import React, { useEffect, useState, useRef, Fragment } from "react";
import { Link, json, useNavigate } from "react-router-dom";
import DataService from "../../../services/data.service";
import "../../../CustomCss/managecompany.css";
import Header from "../../../common/Header";
import Footer from "../../../common/Footer";
import Sidebar from "../../../common/sidebar";
import { toast } from "react-toastify";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Autocomplete from "react-google-autocomplete";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";


const EditCompany = () => {
  const navigate = useNavigate();
  const form = useRef();
  const inputFileRef = useRef();
  const imgRef = useRef();
  const qualityinputFileRef = useRef();
  const qualityimgRef = useRef();
  const rdinputFileRef = useRef();
  const rdimgRef = useRef();
  const companylogoinputFileRef = useRef();
  const companylogoimgRef = useRef();
  const companyphotoinputFileRef = useRef();
  const companyphotoimgRef = useRef();
  const tradeinputFileRef = useRef();
  const tradeimgRef = useRef();
  const [store, setStore] = useState({});
  const [allCategory, setAllCategory] = useState([]);
  const [masterCategory, setmastercategory] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([]);

  const [products, setProducts] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [showOne, setShowOne] = useState(true);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [showFour, setShowFour] = useState(false);
  const [showFive, setShowFive] = useState(false);
  const [showSix, setShowSix] = useState(false);
  const [showSeven, setShowSeven] = useState(false);
  const [showeight, setShowEight] = useState(false);
  const [showAllMain, setshowAllMain] = useState(false);

  // Basic Company Details Field States Start

  const [companyName, setCompanyName] = useState("");
  const [locationState, setLocationState] = useState("");
  const [locationCountry, setLocationCountry] = useState(
    store?.register_country
  );
  const [operationalStreet, setOperationalStreet] = useState("");
  const [operationalCity, setOperationalCity] = useState("");
  const [operationalCountry, setOperationalCountry] = useState("");
  const [operationalZipCode, setOperationalZipCode] = useState("");
  const [mainCategory, setMainCategory] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [mainProducts, setMainProducts] = useState("");
  const [otherProducts, setOtherProducts] = useState("");
  const [newProductsList, setNewProductsList] = useState([]);
  const [otherProductsList, setOtherProductsList] = useState([]);
  const [filteredProductsList, setFilteredProductsList] = useState([]);
  const [companyRegisterYear, setCompanyRegisterYear] = useState("");
  const [companyWebsiteUrl, setCompanyWebsiteUrl] = useState("");
  const [legalOwner, setLegalOwner] = useState("");
  const [officeSize, setOfficeSize] = useState("");
  const [companyAdvantages, setCompanyAdvantages] = useState("");


  const containerStyle = {
    height: '350px'
  };
  const selectCountry = (val) => {
    setLocationCountry(val);
  };

  const selectRegion = (val) => {
    setLocationState(val);
  };

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [map, setMap] = React.useState(null);
  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = React.useState({
    lat: 29.514233,
    lng: -95.237264
  });

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    setMap(map)
  }, [])

  const onMapClick = (e) => {
    setMarkers((current) => [
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      }
    ]);
    setCenter(e.latLng);
  };

  // Basic Company Details Field States End

  // Manufacturing Capability Field States Start

  const [showContentOne, setShowContentOne] = useState(true);
  const [showContentTwo, setShowContentTwo] = useState(true);
  const [showContentThree, setShowContentThree] = useState(true);
  const [showContentFour, setShowContentFour] = useState(true);
  const [showProductionProcess, setShowProductionProcess] = useState("yes");
  const [showProductionEquipment, setShowProductionEquipment] = useState("yes");
  const [showProductionLine, setShowProductionLine] = useState("yes");
  const [annualProductionCapacity, setAnnualProductionCapacity] =
    useState("yes");
  const [processName, setProcessName] = useState("");
  const [processPicture, setProcessPicture] = useState(null);
  const [processDescribe, setProcessDescribe] = useState("");
  const [equipmentModel, setEquipmentModel] = useState("");
  const [equipmentName, setEquipmentName] = useState("");
  const [equipmentQuantity, setEquipmentQuantity] = useState("");
  const [productionLineName, setProductionLineName] = useState("");
  const [supervisorNumber, setSupervisorNumber] = useState("");
  const [numberofOperators, setNumberofOperators] = useState("");
  const [qcQaNumber, setQcQaNumber] = useState("");
  const [factorylocation, setFactorylocation] = useState("");
  const [factorySize, setFactorySize] = useState("");
  const [manufactureContract, setManufactureContract] = useState([]);
  const [qCStaff, setQCStaff] = useState("");
  const [rDStaff, setRDStaff] = useState("");
  const [productionLines, setProductionLines] = useState("");
  const [annualOutputValue, setAnnualOutputValue] = useState("");
  const [productName, setProductName] = useState("");
  const [unitsProduced, setUnitsProduced] = useState("");
  const [highestEverAnnual, setHighestEverAnnual] = useState("");
  const [highestEverAnnualUnitsType, setHighestEverAnnualUnitsType] =
    useState("");
  const [unitsProducedUnitsType, setUnitsProducedUnitsType] = useState("");

  // Manufacturing Capability Field States End

  // Quality Control Field States Start

  const [showContentFive, setShowContentFive] = useState(true);
  const [showContentSix, setShowContentSix] = useState(true);
  const [qualityControlProcess, setQualityControlProcess] = useState("yes");
  const [displayTestingEquipment, setDisplayTestingEquipment] = useState("yes");
  const [qualityprocessName, setQualityProcessName] = useState("");
  const [qualityprocessPicture, setQualityProcessPicture] = useState("");
  const [qualityprocessDescribe, setQualityProcessDescribe] = useState("");
  const [qualityequipmentModel, setQualityEquipmentModel] = useState("");
  const [qualityequipmentName, setQualityEquipmentName] = useState("");
  const [qualityequipmentQuantity, setQualityEquipmentQuantity] = useState("");

  // Quality Control  Field States End

  // Export Capability Field States Start

  const [showContentSeven, setShowContentSeven] = useState(true);
  const [annualRevenue, setAnnualRevenue] = useState("");
  const [exportPercentage, setExportPercentage] = useState("");
  const [mainMarksNorthAmerica, setMainMarksNorthAmerica] = useState("");
  const [mainMarksSouthAmerica, setMainMarksSouthAmerica] = useState("");
  const [mainMarksEasternEurope, setMainMarksEasternEurope] = useState("");
  const [mainMarksSouthEastAsia, setMainMarksSouthEastAsia] = useState("");
  const [mainMarksAfrica, setMainMarksAfrica] = useState("");
  const [mainMarksOceania, setMainMarksOceania] = useState("");
  const [mainMarksMidEast, setMainMarksMidEast] = useState("");
  const [mainMarksEasternAsia, setMainMarksEasternAsia] = useState("");
  const [mainMarksWesternEurope, setMainMarksWesternEurope] = useState("");
  const [mainMarksCentralAmerica, setMainMarksCentralAmerica] = useState("");
  const [mainMarksNorthernEurope, setMainMarksNorthernEurope] = useState("");
  const [mainMarksSouthernEurope, setMainMarksSouthernEurope] = useState("");
  const [mainMarksSouthAsia, setMainMarksSouthAsia] = useState("");
  const [mainMarksDomesticMarket, setMainMarksDomesticMarket] = useState("");
  const [exportStartYear, setExportStartYear] = useState("");
  const [addCustomerCase, setAddCustomerCase] = useState("yes");
  const [customerCaseProjectName, setCustomerCaseProjectName] = useState("");
  const [customerCaseCountry, setCustomerCaseCountry] = useState("");
  const [customerCaseProducts, setCustomerCaseProducts] = useState("");
  const [customerCaseAnnualTurnover, setCustomerCaseAnnualTurnover] =
    useState("");
  const [noOfEmployeesTrade, setNoOfEmployeesTrade] = useState("");
  const [nearestPort, setNearestPort] = useState("");
  const [averageLeadTime, setAverageLeadTime] = useState("");
  const [companyOverseasOffice, setCompanyOverseasOffice] = useState(0);
  const [acceptedDeliveryTerms, setAcceptedDeliveryTerms] = useState([]);
  const [acceptedPaymentMethods, setAcceptedPaymentMethods] = useState([]);
  const [acceptedPaymentTypes, setAcceptedPaymentTypes] = useState([]);
  const [languagesSpoken, setLanguagesSpoken] = useState([]);

  // Export Capability Field Ends Here

  // R & D Capability Field Start Here

  const [showContentEight, setShowContentEight] = useState(true);
  const [showRDProcess, setShowRDProcess] = useState("yes");
  const [rdProcessName, setRDrocessName] = useState("");
  const [rdProcessPicture, setRDrocessPicture] = useState("");
  const [rdProcessDescribe, setRDrocessDescribe] = useState("");

  // R & D Capability Field End Here

  // Company Introduction Field Start Here

  const [companyLogo, setCompanyLogo] = useState("");
  const [companyIntro, setCompanyIntro] = useState("");
  const [companyPhoto, setCompanyPhoto] = useState("");
  const [showContentNine, setShowContentNine] = useState(true);
  const [attendTradeShows, setAttendTradeShows] = useState("yes");
  const [tradeShowName, setTradeShowName] = useState("");
  const [dateAttendedMonth, setDateAttendedMonth] = useState("");
  const [dateAttendedYear, setDateAttendedYear] = useState("");
  const [hostCountry, setHostCountry] = useState("");
  const [tradeShowIntroduction, setTradeShowIntroduction] = useState("");
  const [tradeShowPhoto, setTradeShowPhoto] = useState("");
  const currentYear = 2025;
  const startYear = 1980;

  const years = [];
  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  // Company Introduction Field Ends Here

  const handleAcceptedDTerms = (event) => {
    // const checkboxValue = event.target.value;
    // const isChecked = event.target.checked;
    // if (isChecked) {
    //   setAcceptedDeliveryTerms((prevValues) => [...prevValues, checkboxValue]);
    // } else {
    //   setAcceptedDeliveryTerms((prevValues) =>
    //     prevValues.filter((value) => value !== checkboxValue)
    //   );
    // }
  };

  const handlePaymentMethods = (event) => {
    // const checkboxValue = event.target.value;
    // const isChecked = event.target.checked;
    // if (isChecked) {
    //   setAcceptedPaymentMethods((prevValues) => [...prevValues, checkboxValue]);
    // } else {
    //   setAcceptedPaymentMethods((prevValues) =>
    //     prevValues.filter((value) => value !== checkboxValue)
    //   );
    // }
  };

  const handlePaymentTypes = (event) => {
    // const checkboxValue = event.target.value;
    // const isChecked = event.target.checked;
    // if (isChecked) {
    //   setAcceptedPaymentTypes((prevValues) => [...prevValues, checkboxValue]);
    // } else {
    //   setAcceptedPaymentTypes((prevValues) =>
    //     prevValues.filter((value) => value !== checkboxValue)
    //   );
    // }
  };

  const handleLanguagesSpoken = (event) => {
    // const checkboxValue = event.target.value;
    // const isChecked = event.target.checked;
    // if (isChecked) {
    //   setLanguagesSpoken((prevValues) => [...prevValues, checkboxValue]);
    // } else {
    //   setLanguagesSpoken((prevValues) =>
    //     prevValues.filter((value) => value !== checkboxValue)
    //   );
    // }
  };

  const handleContractManufacturing = (event) => {
    // const checkboxValue = event.target.value;
    // const isChecked = event.target.checked;
  
    // setManufactureContract((prevValues) => {
    //   if (isChecked) {
    //     return [...prevValues, checkboxValue];
    //   } else {
    //     return prevValues?.filter((value) => value !== checkboxValue);
    //   }
    // });
  };
  
  
  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedItems((prevValues) => [...prevValues, checkboxValue]);
    } else {
      setCheckedItems((prevValues) =>
        prevValues.filter((value) => value !== checkboxValue)
      );
    }
  };

  const onProcessFileChangeCapture = (e) => {
    /*Selected files data can be collected here.*/
    const processPicture = e.target.files[0];
    setProcessPicture(e.target.files);
    const reader = new FileReader();
    const url = reader.readAsDataURL(processPicture);
    reader.onloadend = function (theFile) {
      var image = new Image();
      image.src = theFile.target.result;
      imgRef.current.src = image.src;
    };
  };
  const triggerFile = () => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };

  const onQualityFileChangeCapture = (e) => {
    /*Selected files data can be collected here.*/
    const qualityprocessPicture = e.target.files[0];
    setQualityProcessPicture(e.target.files);
    const reader = new FileReader();
    const url = reader.readAsDataURL(qualityprocessPicture);
    reader.onloadend = function (theFile) {
      var image = new Image();
      image.src = theFile.target.result;
      qualityimgRef.current.src = image.src;
    };
  };
  const qualitytriggerFile = () => {
    /*Collecting node-element and performing click*/
    qualityinputFileRef.current.click();
  };

  const onRDFileChangeCapture = (e) => {
    /*Selected files data can be collected here.*/
    const rdProcessPicture = e.target.files[0];
    setRDrocessPicture(e.target.files);
    const reader = new FileReader();
    const url = reader.readAsDataURL(rdProcessPicture);
    reader.onloadend = function (theFile) {
      var image = new Image();
      image.src = theFile.target.result;
      rdimgRef.current.src = image.src;
    };
  };
  const rdtriggerFile = () => {
    /*Collecting node-element and performing click*/
    rdinputFileRef.current.click();
  };
  const onLogoFileChangeCapture = (e) => {
    /*Selected files data can be collected here.*/
    const companyLogo = e.target.files[0];
    setCompanyLogo(e.target.files);
    const reader = new FileReader();
    const url = reader.readAsDataURL(companyLogo);
    reader.onloadend = function (theFile) {
      var image = new Image();
      image.src = theFile.target.result;
      companylogoimgRef.current.src = image.src;
    };
  };
  const companylogotriggerFile = () => {
    /*Collecting node-element and performing click*/
    companylogoinputFileRef.current.click();
  };

  const onPhotoFileChangeCapture = (e) => {
    /*Selected files data can be collected here.*/
    const companyPhoto = e.target.files[0];
    setCompanyPhoto(e.target.files);
    const reader = new FileReader();
    const url = reader.readAsDataURL(companyPhoto);
    reader.onloadend = function (theFile) {
      var image = new Image();
      image.src = theFile.target.result;
      companyphotoimgRef.current.src = image.src;
    };
  };
  const companyphototriggerFile = () => {
    /*Collecting node-element and performing click*/
    companyphotoinputFileRef.current.click();
  };

  const onTradeFileChangeCapture = (e) => {
    /*Selected files data can be collected here.*/
    const tradeShowPhoto = e.target.files[0];
    setTradeShowPhoto(e.target.files);
    const reader = new FileReader();
    const url = reader.readAsDataURL(tradeShowPhoto);
    reader.onloadend = function (theFile) {
      var image = new Image();
      image.src = theFile.target.result;
      tradeimgRef.current.src = image.src;
    };
  };
  const tradetriggerFile = () => {
    /*Collecting node-element and performing click*/
    tradeinputFileRef.current.click();
  };

  const handleVisibOne = (e) => {
    const business_type = document.querySelectorAll(".business_type:checked");
    if (business_type.length < 1) {
      toast.error("Please Select atleat One Business Type");
    } else {
      handleSubmitOne(e);
    }
  };
  const handleVisibOneBack = () => {
    setShowOne(true);
    setshowAllMain(false);
    window.scrollTo(0, 0);
  };
  const handleVisibTwo = (e) => {
    if (companyName === "") {
      toast.error("Company Name is Required");
      return false;
    } else if (locationState === "") {
      toast.error("State of Registration is Required");
      return false;
    } else if (locationCountry === "") {
      toast.error("Country of Registration is Required");
      return false;
    } else if (locationCountry === "") {
      toast.error("Country of Registration is Required");
      return false;
    } else if (operationalStreet === "") {
      toast.error("Operational Street is Required");
      return false;
    } else if (operationalCity === "") {
      toast.error("Operational City is Required");
      return false;
    } else if (operationalCountry === "") {
      toast.error("Operational Country is Required");
      return false;
    } else if (operationalZipCode === "") {
      toast.error("Operational Zip Code is Required");
      return false;
    } else if (mainCategory === "") {
      toast.error("One Main Category is Required");
      return false;
    } else if (companyRegisterYear === "") {
      toast.error("Company Registration Year is Required");
      return false;
    } else if (companyWebsiteUrl === "") {
      toast.error("Company Website Url is Required");
      return false;
    } else if (legalOwner === "") {
      toast.error("Legal Owner is Required");
      return false;
    } else if (officeSize === "") {
      toast.error("Office Size is Required");
      return false;
    } else if (companyAdvantages === "") {
      toast.error("Company Advantages are Required");
      return false;
    } else {
      handleSubmitTwo(e)
    }
  };
  const handleVisibTwoBack = () => {
    setShowTwo(true);
    setShowThree(false);
    window.scrollTo(0, 0);
  };
  const handleVisibThree = (e) => {
    if (factorylocation === "") {
      toast.error("Factory Location is Required");
      return false;
    } else if (factorySize === "") {
      toast.error("Factory Size is Required");
      return false;
    } else if (qCStaff === "") {
      toast.error("No.of QC Staff are Required");
      return false;
    } else if (rDStaff === "") {
      toast.error("No.of R & D Staff is Required");
      return false;
    } else if (productionLines === "") {
      toast.error("No.of Production Lines is Required");
      return false;
    } else if (annualOutputValue === "") {
      toast.error("Annual Output Value is Required");
      return false;
    } else {
      handleSubmitThree(e)
    }
  };
  const handleVisibThreeBack = () => {
    setShowThree(true);
    setShowFour(false);
    window.scrollTo(0, 0);
  };
  const handleVisibFour = (e) => {
    handleSubmitFour(e)
  };
  const handleVisibFourBack = () => {
    setShowFour(true);
    setShowFive(false);
    window.scrollTo(0, 0);
  };
  const handleVisibFive = (e) => {
    handleSubmitFive(e)
  };
  const handleVisibFiveBack = () => {
    setShowFive(true);
    setShowSix(false);
    window.scrollTo(0, 0);
  };
  const handleVisibSix = (e) => {
    handleSubmitSix(e)
  };



  const handleVisibEight = () => {
    if (annualRevenue === "") {
      toast.error("Annual Revenue is Required");
      return false;
    } else if (exportPercentage === "") {
      toast.error("Export Percentage is Required");
    }

    else if (exportStartYear === "") {
      toast.error("Export Start Year is Required");
      return false;
    } else if (addCustomerCase === "") {
      toast.error("Add Customer case is Required");
      return false;
    } else if (noOfEmployeesTrade === "") {
      toast.error("No.of Employees is Required");
      return false;
    } else if (nearestPort === "") {
      toast.error("Nearest Port is Required");
      return false;
    } else if (averageLeadTime === "") {
      toast.error("Average Lead Time is Required");
      return false;
    } else if (averageLeadTime === "") {
      toast.error("Average Leadf Time is Required");
      return false;
    } else {
      setShowSeven(true);
      setShowSix(false);
      window.scrollTo(0, 0);
    }
  };

  const handleVisibSixBack = () => {
    setShowSix(true);
    setShowSeven(false);
    window.scrollTo(0, 0);
  };
  const handleVisibSevenBack = () => {
    setShowSeven(true);
    setShowEight(false);
    window.scrollTo(0, 0);
  };
  const handleMainProducts = (e) => {
    const options = e.target.options;
    const selectedValues = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setMainProducts(selectedValues);
    const updatedOtherProductsList = newProductsList.filter(item => !selectedValues.includes(item.id));
    setFilteredProductsList(updatedOtherProductsList);
  };

  const handleOtherProducts = (e) => {
    const options = e.target.options;
    const selectedValues = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setOtherProducts(selectedValues);
    const updatedMainProductsList = newProductsList.filter(item => !selectedValues.includes(item.id));
    setNewProductsList(updatedMainProductsList);
  };

  const getData = () => {
    DataService.getAllCategory().then((data) => {
      const catData = data.data.categories;
      setAllCategory(catData);
      const masterCatData = catData.filter((value) => value.type === "0");
      setmastercategory(masterCatData);
    });
  };
  const handleMainCatChange = (mId) => {
    setCategory("")
    setSubCategory("")
    setMainCategory(mId);
    const catList = masterCategory?.find((mat) => mat.type === "0" && mat.id == mId);
    setCategoriesList(catList?.Categories)
  };

  const handleCategoryChange = (cId) => {
    setSubCategory("")
    setCategory(cId);
    const subCatList = categoriesList?.find((mat) => mat.type === "1" && mat.id == cId);
    setSubCategoriesList(subCatList?.Categories)
  };

  const handleSubCategoryChange = (sId) => {
    setMainProducts("")
    setOtherProducts("")
    setSubCategory(sId);
    const productsList = products?.filter((mat) => mat.sub_category_id == sId);
    setNewProductsList(productsList)
    setOtherProductsList(productsList)
    setFilteredProductsList(productsList)
  };

  const getProducts = () => {
    DataService.getProduct()
      .then((data) => {
        setProducts(data?.data?.categories);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.msg);
      });
  };
  const getStore = async () => {
    await getData();
    await DataService.getVendor()
      .then((data) => {
        setStore(data?.data?.data);
        const vendorData = data?.data?.data;
        let business_type = JSON.parse(data?.data?.data?.business_type);
        setCheckedItems(business_type);
        setCompanyName(data?.data?.data?.name);
        setLocationState(data?.data?.data?.register_state);
        setLocationCountry(data?.data?.data?.register_country);
        setOperationalStreet(data?.data?.data?.opertaion_steet);
        setOperationalCity(data?.data?.data?.operation_city);
        setOperationalCountry(data?.data?.data?.operation_country);
        setOperationalZipCode(data?.data?.data?.operation_zip);
        const mainCategoryId = vendorData?.main_category_id;
        const subCategoryId = vendorData?.main_sub_category_id;
        const subSubCategoryId = vendorData?.main_sub_sub_category_id;
        if (mainCategoryId) {
          setMainCategory(mainCategoryId);
          const mainCat = masterCategory?.find((cat) => cat.type === "0" && cat.id == mainCategoryId);
          setCategoriesList(mainCat?.Categories);
          if (subCategoryId) {
            setCategory(subCategoryId);
            const subCat = mainCat?.Categories?.find((cat) => cat.type === "1" && cat.id == subCategoryId);
            setSubCategoriesList(subCat?.Categories);
            if (subSubCategoryId) {
              setSubCategory(subSubCategoryId);
              const productsList = products?.filter((prod) => prod.sub_category_id == subSubCategoryId);
              setNewProductsList(productsList);
              setOtherProductsList(productsList);
              setFilteredProductsList(productsList);
            }
          }
        }
        setMainProducts(vendorData?.main_products?.split(","));
        setOtherProducts(vendorData?.other_products?.split(","));
        setCompanyRegisterYear(data?.data?.data?.register_year);
        setCompanyWebsiteUrl(data?.data?.data?.website);
        setLegalOwner(data?.data?.data?.legal_owner);
        setOfficeSize(data?.data?.data?.office_size);
        setCompanyAdvantages(JSON.parse(data?.data?.data?.company_advantages));
        setFactorylocation(data?.data?.data?.factory_location);
        setFactorySize(data?.data?.data?.factory_size);
        let manufacture_contract = data?.data?.data?.manufacture_contract;
        setManufactureContract(manufacture_contract);
        setQCStaff(data?.data?.data?.qc_staff);
        setRDStaff(data?.data?.data?.rd_staff);
        setProductionLines(data?.data?.data?.production_lines_count);
        setAnnualOutputValue(data?.data?.data?.annual_op_value);
        setAnnualRevenue(data?.data?.data?.annual_revenue);
        setExportPercentage(data?.data?.data?.export_percentage);
        setExportStartYear(data?.data?.data?.started_exporting);
        setNoOfEmployeesTrade(data?.data?.data?.emp_in_trade_department);
        setNearestPort(data?.data?.data?.nearest_port);
        setAverageLeadTime(data?.data?.data?.average_lead_time);
        setCompanyOverseasOffice(data?.data?.data?.overseas_office);
        let delivery_terms = data?.data?.data?.delivery_terms;
        setAcceptedDeliveryTerms(delivery_terms);
        let payment_currency = data?.data?.data?.payment_currency;
        setAcceptedPaymentMethods(payment_currency);
        let language_spoken = data?.data?.data?.language_spoken;
        setLanguagesSpoken(language_spoken);
        let payment_types = JSON.parse(data?.data?.data?.payment_types);
        setAcceptedPaymentTypes(payment_types);
        let production_process = JSON.parse(
          data?.data?.data?.production_process
        );
        setProcessName(production_process.name);
        setProcessDescribe(production_process.description);
        let production_equipment = JSON.parse(
          data?.data?.data?.production_equipment
        );
        setEquipmentName(production_equipment.name);
        setEquipmentModel(production_equipment.model);
        setEquipmentQuantity(production_equipment.quantity);
        let production_line = JSON.parse(data?.data?.data?.production_line);
        setProductionLineName(production_line.name);
        setSupervisorNumber(production_line.supervisorNo);
        setNumberofOperators(production_line.noOfOperators);
        setQcQaNumber(production_line.qaqcNumber);
        let production_capacity = JSON.parse(
          data?.data?.data?.production_capacity
        );
        setProductName(production_capacity.name);
        setUnitsProduced(production_capacity.unitsProduced);
        setUnitsProducedUnitsType(production_capacity.unitsProducedUnitType);
        setHighestEverAnnual(production_capacity.highestOutput);
        setHighestEverAnnualUnitsType(production_capacity.highestEverUnitType);
        let qc_process = JSON.parse(data?.data?.data?.qc_process);
        setQualityProcessName(qc_process.name);
        setQualityProcessDescribe(qc_process.description);
        let testing_equipment = JSON.parse(data?.data?.data?.testing_equipment);
        setQualityEquipmentName(testing_equipment.name);
        setQualityEquipmentModel(testing_equipment.model);
        setQualityEquipmentQuantity(testing_equipment.quantity);
        let rd_process = JSON.parse(data?.data?.data?.rd_process);
        setRDrocessName(rd_process.name);
        setRDrocessDescribe(rd_process.description);
        let marks_distribution = JSON.parse(
          data?.data?.data?.marks_distribution
        );
        setMainMarksNorthAmerica(marks_distribution.northAmerica);
        setMainMarksSouthAmerica(marks_distribution.southAmerica);
        setMainMarksEasternEurope(marks_distribution.easternEurope);
        setMainMarksSouthEastAsia(marks_distribution.southEastAsia);
        setMainMarksAfrica(marks_distribution.africa);
        setMainMarksOceania(marks_distribution.oceania);
        setMainMarksMidEast(marks_distribution.midEast);
        setMainMarksEasternAsia(marks_distribution.easternAsia);
        setMainMarksWesternEurope(marks_distribution.westernEurope);
        setMainMarksNorthernEurope(marks_distribution.nothernEurope);
        setMainMarksSouthernEurope(marks_distribution.southernEurope);
        setMainMarksSouthAsia(marks_distribution.southAsia);
        setMainMarksDomesticMarket(marks_distribution.domesticMarket);
        let customer_case = JSON.parse(data?.data?.data?.customer_case);
        setCustomerCaseProjectName(customer_case.name);
        setCustomerCaseProducts(customer_case.product);
        setCustomerCaseCountry(customer_case.country);
        setCustomerCaseAnnualTurnover(customer_case.turnover);
        let trade_shows = JSON.parse(data?.data?.data?.trade_shows);
        setTradeShowName(trade_shows.name);
        setTradeShowIntroduction(trade_shows.intro);
        setDateAttendedMonth(trade_shows.attendedMonth);
        setDateAttendedYear(trade_shows.attendedYear);
        setHostCountry(trade_shows.hostCountry);
        setCompanyIntro(data?.data?.data?.description);
        let map_location = JSON.parse(data?.data?.data?.map_location);
        if (map_location.lat && map_location.lng) {
          setTimeout(function () {
            var latlongs = {
              lat: parseFloat(map_location.lat),
              lng: parseFloat(map_location.lng)
            }
            setMarkers([latlongs])
            setCenter(latlongs);
          }, 2000)

        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.msg);
      });
  };
  useEffect(() => {
    getStore();
    getData();
    getProducts();
    setTimeout(function () {
      setIsLoaded(true);
    }, 2000)
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    const data = new FormData();
    const business_type = document.querySelectorAll(".business_type:checked");
    const languages_checked = document.querySelectorAll(
      ".language_check:checked"
    );
    const accepted_paymentType = document.querySelectorAll(
      ".accepted_paymentType:checked"
    );
    const payment_currency = document.querySelectorAll(
      ".payment_currency:checked"
    );
    const contract_manufacturing = document.querySelectorAll(
      ".contract_manufacturing:checked"
    );
    const delivery_terms = document.querySelectorAll(".delivery_terms:checked");
    data.append("name", companyName);
    for (let r = 0; r < business_type.length; r++) {
      data.append("business_type", business_type[r].value);
    }
    data.append("website", companyWebsiteUrl);
    data.append("description", companyIntro);
    data.append("legal_owner", legalOwner);
    data.append("register_year", companyRegisterYear);
    data.append("register_state", locationState);
    data.append("register_country", locationCountry);
    data.append("opertaion_steet", operationalStreet);
    data.append("operation_city", operationalCity);
    data.append("operation_country", operationalCountry);
    data.append("operation_zip", operationalZipCode);
    if (markers && markers.length > 0) {
      data.append("map_location[lat]", markers[0].lat);
      data.append("map_location[lng]", markers[0].lng);
    }
    data.append("main_category_id", mainCategory);
    data.append("main_products", mainProducts);
    data.append("other_products", otherProducts);
    data.append("office_size", officeSize);
    data.append("company_advantages", companyAdvantages);
    data.append("production_process[name]", processName);
    data.append("production_process[description]", processDescribe);
    data.append("production_equipment[name]", equipmentName);
    data.append("production_equipment[model]", equipmentModel);
    data.append("production_equipment[quantity]", equipmentQuantity);
    data.append("production_line[name]", productionLineName);
    data.append("production_line[supervisorNo]", supervisorNumber);
    data.append("production_line[noOfOperators]", numberofOperators);
    data.append("production_line[qaqcNumber]", qcQaNumber);
    data.append("production_capacity[name]", productName);
    data.append("production_capacity[unitsProduced]", unitsProduced);
    data.append(
      "production_capacity[unitsProducedUnitType]",
      unitsProducedUnitsType
    );
    data.append("production_capacity[highestOutput]", highestEverAnnual);
    data.append(
      "production_capacity[highestEverUnitType]",
      highestEverAnnualUnitsType
    );
    data.append("production_lines_count", productionLines);
    data.append("factory_location", factorylocation);
    data.append("factory_size", factorySize);
    for (let l = 0; l < contract_manufacturing.length; l++) {
      data.append("contract_manufacturing", contract_manufacturing[l].value);
    }
    data.append("qc_staff", qCStaff);
    data.append("rd_staff", rDStaff);
    data.append("annual_op_value", annualOutputValue);
    data.append("qc_process[name]", qualityprocessName);
    data.append("qc_process[description]", qualityprocessDescribe);
    data.append("testing_equipment[name]", qualityequipmentName);
    data.append("testing_equipment[model]", qualityequipmentModel);
    data.append("testing_equipment[quantity]", qualityequipmentQuantity);
    data.append("rd_process[name]", rdProcessName);
    data.append("rd_process[description]", rdProcessDescribe);
    data.append("annual_revenue", annualRevenue);
    data.append("export_percentage", exportPercentage);
    data.append("marks_distribution[northAmerica]", mainMarksNorthAmerica);
    data.append("marks_distribution[southAmerica]", mainMarksSouthAmerica);
    data.append("marks_distribution[easternEurope]", mainMarksEasternEurope);
    data.append("marks_distribution[southEastAsia]", mainMarksSouthEastAsia);
    data.append("marks_distribution[africa]", mainMarksAfrica);
    data.append("marks_distribution[oceania]", mainMarksOceania);
    data.append("marks_distribution[midEast]", mainMarksMidEast);
    data.append("marks_distribution[easternAsia]", mainMarksEasternAsia);
    data.append("marks_distribution[westernEurope]", mainMarksWesternEurope);
    data.append("marks_distribution[centralAmerica]", mainMarksCentralAmerica);
    data.append("marks_distribution[nothernEurope]", mainMarksNorthernEurope);
    data.append("marks_distribution[southernEurope]", mainMarksSouthernEurope);
    data.append("marks_distribution[southAsia]", mainMarksSouthAsia);
    data.append("marks_distribution[domesticMarket]", mainMarksDomesticMarket);
    data.append("started_exporting", exportStartYear);
    data.append("customer_case[name]", customerCaseProjectName);
    data.append("customer_case[country]", customerCaseCountry);
    data.append("customer_case[product]", customerCaseProducts);
    data.append("customer_case[turnover]", customerCaseAnnualTurnover);
    data.append("emp_in_trade_department", noOfEmployeesTrade);
    data.append("nearest_port", nearestPort);
    data.append("average_lead_time", averageLeadTime);
    data.append("overseas_office", companyOverseasOffice);
    for (let m = 0; m < delivery_terms.length; m++) {
      data.append("delivery_terms", delivery_terms[m].value);
    }
    for (let f = 0; f < payment_currency.length; f++) {
      data.append("payment_currency", payment_currency[f].value);
    }
    for (let c = 0; c < accepted_paymentType.length; c++) {
      data.append("payment_types", accepted_paymentType[c].value);
    }
    for (let v = 0; v < languages_checked.length; v++) {
      data.append("language_spoken", languages_checked[v].value);
    }
    data.append("trade_shows[name]", tradeShowName);
    data.append("trade_shows[attendedYear]", dateAttendedYear);
    data.append("trade_shows[attendedMonth]", dateAttendedMonth);
    data.append("trade_shows[hostCountry]", hostCountry);
    data.append("trade_shows[intro]", tradeShowIntroduction);

    if (companyLogo && companyLogo.length > 0) {
      data.append("company_logo", companyLogo[0]);
    }
    if (companyPhoto && companyPhoto.length > 0) {
      data.append("company_photo", companyPhoto[0]);
    }
    if (tradeShowPhoto && tradeShowPhoto.length > 0) {
      data.append("trade_shows", tradeShowPhoto[0]);
    }
    if (processPicture && processPicture.length > 0) {
      data.append("production_process", processPicture[0]);
    }
    if (qualityprocessPicture && qualityprocessPicture.length > 0) {
      data.append("qc_process", qualityprocessPicture[0]);
    }
    if (rdProcessPicture && rdProcessPicture.length > 0) {
      data.append("rd_process", rdProcessPicture[0]);
    }
    DataService.addVendor(data).then(
      () => {
        toast.success("Form Submitted Successfully !!!");
        navigate('/dashboard')
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage);
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  const handleSubmitOne = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    const business_type = document.querySelectorAll(".business_type:checked");
    const bType = [];
    for (let r = 0; r < business_type.length; r++) {
      bType.push(business_type[r].value);
    }
    const data = {
      "business_type": bType,
    };
    DataService.addVendor(data).then(
      () => {
        toast.success("Form Submitted Successfully !!!. Going to next Step!");
        setShowOne(false);
        setshowAllMain(true);
        setShowTwo(true);
        window.scrollTo(0, 0);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage);
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  const handleSubmitTwo = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    // const data = new FormData();
    // data.append("name", companyName);
    // data.append("website", companyWebsiteUrl);
    // data.append("legal_owner", legalOwner);
    // data.append("register_year", companyRegisterYear);
    // data.append("register_state", locationState);
    // data.append("register_country", locationCountry);
    // data.append("opertaion_steet", operationalStreet);
    // data.append("operation_city", operationalCity);
    // data.append("operation_country", operationalCountry);
    // data.append("operation_zip", operationalZipCode);
    // if (markers && markers.length > 0) {
    //   data.append("map_location[lat]", markers[0].lat);
    //   data.append("map_location[lng]", markers[0].lng);
    // }
    // data.append("main_category_id", mainCategory);
    // data.append("main_products", mainProducts);
    // data.append("other_products", otherProducts);
    // data.append("office_size", officeSize);
    // data.append("company_advantages", companyAdvantages);
    const data = {
      name: companyName,
      website: companyWebsiteUrl,
      legal_owner: legalOwner,
      register_year: companyRegisterYear,
      register_state: locationState,
      register_country: locationCountry,
      opertaion_steet: operationalStreet,
      operation_city: operationalCity,
      operation_country: operationalCountry,
      operation_zip: operationalZipCode,
      map_location: markers && markers.length > 0 ? { lat: markers[0].lat, lng: markers[0].lng } : undefined,
      main_category_id: mainCategory,
      main_sub_category_id: category,
      main_sub_sub_category_id: subCategory,
      main_products: mainProducts?.join(","),
      other_products: otherProducts?.join(","),
      office_size: officeSize,
      company_advantages: companyAdvantages
    };
    DataService.addVendor(data).then(
      () => {
        toast.success("Form Submitted Successfully !!!. Going to next Step!");
        setShowTwo(false);
        setShowThree(true);
        window.scrollTo(0, 0);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage);
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  const handleSubmitThree = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    const data = new FormData();
    const contract_manufacturing = document.querySelectorAll(
      ".contract_manufacturing:checked"
    );
    data.append("production_process[name]", processName);
    data.append("production_process[description]", processDescribe);
    data.append("production_equipment[name]", equipmentName);
    data.append("production_equipment[model]", equipmentModel);
    data.append("production_equipment[quantity]", equipmentQuantity);
    data.append("production_line[name]", productionLineName);
    data.append("production_line[supervisorNo]", supervisorNumber);
    data.append("production_line[noOfOperators]", numberofOperators);
    data.append("production_line[qaqcNumber]", qcQaNumber);
    data.append("production_capacity[name]", productName);
    data.append("production_capacity[unitsProduced]", unitsProduced);
    data.append(
      "production_capacity[unitsProducedUnitType]",
      unitsProducedUnitsType
    );
    data.append("production_capacity[highestOutput]", highestEverAnnual);
    data.append(
      "production_capacity[highestEverUnitType]",
      highestEverAnnualUnitsType
    );
    data.append("production_lines_count", productionLines);
    data.append("factory_location", factorylocation);
    data.append("factory_size", factorySize);
    for (let l = 0; l < contract_manufacturing.length; l++) {
      data.append("manufacture_contract", contract_manufacturing[l].value);
    }
    data.append("qc_staff", qCStaff);
    data.append("rd_staff", rDStaff);
    data.append("annual_op_value", annualOutputValue);
    if (processPicture && processPicture.length > 0) {
      data.append("production_process", processPicture[0]);
    }
    DataService.addVendor(data).then(
      () => {
        toast.success("Form Submitted Successfully !!!. Going to next Step!");
        setShowThree(false);
        setShowFour(true);
        window.scrollTo(0, 0);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage);
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  const handleSubmitFour = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    const data = new FormData();
    data.append("qc_process[name]", qualityprocessName);
    data.append("qc_process[description]", qualityprocessDescribe);
    data.append("testing_equipment[name]", qualityequipmentName);
    data.append("testing_equipment[model]", qualityequipmentModel);
    data.append("testing_equipment[quantity]", qualityequipmentQuantity);
    if (qualityprocessPicture && qualityprocessPicture.length > 0) {
      data.append("qc_process", qualityprocessPicture[0]);
    }
    DataService.addVendor(data).then(
      () => {
        toast.success("Form Submitted Successfully !!!. Going to next Step!");
        setShowFour(false);
        setShowFive(true);
        window.scrollTo(0, 0);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage);
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  const handleSubmitFive = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    const data = new FormData();
    data.append("rd_process[name]", rdProcessName);
    data.append("rd_process[description]", rdProcessDescribe);
    if (rdProcessPicture && rdProcessPicture.length > 0) {
      data.append("rd_process", rdProcessPicture[0]);
    }
    DataService.addVendor(data).then(
      () => {
        toast.success("Form Submitted Successfully !!!. Going to next Step!");
        setShowFive(false);
        setShowSix(true);
        window.scrollTo(0, 0);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage);
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  const handleSubmitSix = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    // const data = new FormData();
    // const languages_checked = document.querySelectorAll(
    //   ".language_check:checked"
    // );
    // const accepted_paymentType = document.querySelectorAll(
    //   ".accepted_paymentType:checked"
    // );
    // const payment_currency = document.querySelectorAll(
    //   ".payment_currency:checked"
    // );
    // const delivery_terms = document.querySelectorAll(".delivery_terms:checked");
    // data.append("annual_revenue", annualRevenue);
    // data.append("export_percentage", exportPercentage);
    // data.append("marks_distribution[northAmerica]", mainMarksNorthAmerica);
    // data.append("marks_distribution[southAmerica]", mainMarksSouthAmerica);
    // data.append("marks_distribution[easternEurope]", mainMarksEasternEurope);
    // data.append("marks_distribution[southEastAsia]", mainMarksSouthEastAsia);
    // data.append("marks_distribution[africa]", mainMarksAfrica);
    // data.append("marks_distribution[oceania]", mainMarksOceania);
    // data.append("marks_distribution[midEast]", mainMarksMidEast);
    // data.append("marks_distribution[easternAsia]", mainMarksEasternAsia);
    // data.append("marks_distribution[westernEurope]", mainMarksWesternEurope);
    // data.append("marks_distribution[centralAmerica]", mainMarksCentralAmerica);
    // data.append("marks_distribution[nothernEurope]", mainMarksNorthernEurope);
    // data.append("marks_distribution[southernEurope]", mainMarksSouthernEurope);
    // data.append("marks_distribution[southAsia]", mainMarksSouthAsia);
    // data.append("marks_distribution[domesticMarket]", mainMarksDomesticMarket);
    // data.append("started_exporting", exportStartYear);
    // data.append("customer_case[name]", customerCaseProjectName);
    // data.append("customer_case[country]", customerCaseCountry);
    // data.append("customer_case[product]", customerCaseProducts);
    // data.append("customer_case[turnover]", customerCaseAnnualTurnover);
    // data.append("emp_in_trade_department", noOfEmployeesTrade);
    // data.append("nearest_port", nearestPort);
    // data.append("average_lead_time", averageLeadTime);
    // data.append("overseas_office", companyOverseasOffice);
    // for (let m = 0; m < delivery_terms.length; m++) {
    //   data.append("delivery_terms", delivery_terms[m].value);
    // }
    // for (let f = 0; f < payment_currency.length; f++) {
    //   data.append("payment_currency", payment_currency[f].value);
    // }
    // for (let c = 0; c < accepted_paymentType.length; c++) {
    //   data.append("payment_types", accepted_paymentType[c].value);
    // }
    // for (let v = 0; v < languages_checked.length; v++) {
    //   data.append("language_spoken", languages_checked[v].value);
    // }
    const data = {
      annual_revenue: annualRevenue,
      export_percentage: exportPercentage,
      marks_distribution: {
        northAmerica: mainMarksNorthAmerica,
        southAmerica: mainMarksSouthAmerica,
        easternEurope: mainMarksEasternEurope,
        southEastAsia: mainMarksSouthEastAsia,
        africa: mainMarksAfrica,
        oceania: mainMarksOceania,
        midEast: mainMarksMidEast,
        easternAsia: mainMarksEasternAsia,
        westernEurope: mainMarksWesternEurope,
        centralAmerica: mainMarksCentralAmerica,
        nothernEurope: mainMarksNorthernEurope,
        southernEurope: mainMarksSouthernEurope,
        southAsia: mainMarksSouthAsia,
        domesticMarket: mainMarksDomesticMarket,
      },
      started_exporting: exportStartYear,
      customer_case: {
        name: customerCaseProjectName,
        country: customerCaseCountry,
        product: customerCaseProducts,
        turnover: customerCaseAnnualTurnover,
      },
      emp_in_trade_department: noOfEmployeesTrade,
      nearest_port: nearestPort,
      average_lead_time: averageLeadTime,
      overseas_office: companyOverseasOffice,
      delivery_terms: Array.from(document.querySelectorAll(".delivery_terms:checked")).map(
        (item) => item.value
      ),
      payment_currency: Array.from(document.querySelectorAll(".payment_currency:checked")).map(
        (item) => item.value
      ),
      payment_types: Array.from(document.querySelectorAll(".accepted_paymentType:checked")).map(
        (item) => item.value
      ),
      language_spoken: Array.from(document.querySelectorAll(".language_check:checked")).map(
        (item) => item.value
      ),
    };
    DataService.addVendor(data).then(
      () => {
        toast.success("Form Submitted Successfully !!!. Going to next Step!");
        setShowSeven(true);
        setShowSix(false);
        window.scrollTo(0, 0);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage);
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  const handleSubmitSeven = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    const data = new FormData();
    data.append("description", companyIntro);
    data.append("trade_shows[name]", tradeShowName);
    data.append("trade_shows[attendedYear]", dateAttendedYear);
    data.append("trade_shows[attendedMonth]", dateAttendedMonth);
    data.append("trade_shows[hostCountry]", hostCountry);
    data.append("trade_shows[intro]", tradeShowIntroduction);

    if (companyLogo && companyLogo.length > 0) {
      data.append("company_logo", companyLogo[0]);
    }
    if (companyPhoto && companyPhoto.length > 0) {
      data.append("company_photo", companyPhoto[0]);
    }
    if (tradeShowPhoto && tradeShowPhoto.length > 0) {
      data.append("trade_shows", tradeShowPhoto[0]);
    }
    DataService.addVendor(data).then(
      () => {
        toast.success("Form Submitted Successfully !!!");
        navigate('/dashboard')
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage);
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  return (
    <>
      <div className="bg-grey h-100">
        <Header />
        <section className="content-area mt-4">
          <Sidebar />
          <div className="Right-content">
            <div className="main-content">
              <form className="mt-4" ref={form}>
                <div className="vendor_rMainSec">
                  <div className={showOne ? "vendor_rStepOne" : "hide_sec"}>
                    <h2>Manage Company Information</h2>
                    <div className="d-flex p-5">
                      <div className="me-4">
                        <p>
                          <strong>Business Type:</strong>
                        </p>
                      </div>
                      <div>
                        <div class="form-check">
                          <input
                            class="form-check-input business_type"
                            type="checkbox"
                            value="manufacturer"
                            id="manufacturer"
                            onChange={handleCheckboxChange}
                            checked={checkedItems?.includes("manufacturer")}
                          />
                          <label
                            class="form-check-label"
                            for="manufacturer"
                          >
                            Manufacturer
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input business_type"
                            type="checkbox"
                            value="trading_company"
                            id="trading_company"
                            onChange={handleCheckboxChange}
                            checked={checkedItems?.includes("trading_company")}
                          />
                          <label
                            class="form-check-label"
                            for="trading_company"
                          >
                            Trading Company
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input business_type"
                            type="checkbox"

                            value="buying_office"
                            id="buying_office"
                            onChange={handleCheckboxChange}
                            checked={checkedItems?.includes("buying_office")}
                          />
                          <label
                            class="form-check-label"
                            for="buying_office"
                          >
                            Buying Office
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input business_type"
                            type="checkbox"
                            value="agent"
                            id="agent"
                            onChange={handleCheckboxChange}
                            checked={checkedItems?.includes("agent")}
                          />
                          <label
                            class="form-check-label"
                            for="agent"
                          >
                            Agent
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input business_type"
                            type="checkbox"

                            value="distributor"
                            id="distributor"
                            onChange={handleCheckboxChange}
                            checked={checkedItems?.includes("distributor")}
                          />
                          <label
                            class="form-check-label"
                            for="distributor"
                          >
                            Distributor/Wholesaler
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input business_type"
                            type="checkbox"

                            value="govt_ministry"
                            id="govt_ministry"
                            onChange={handleCheckboxChange}
                            checked={checkedItems?.includes("govt_ministry")}
                          />
                          <label
                            class="form-check-label"
                            for="govt_ministry"
                          >
                            Government Ministry/Bureau/Commission
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input business_type"
                            type="checkbox"

                            value="association"
                            id="association"
                            onChange={handleCheckboxChange}
                            checked={checkedItems?.includes("association")}
                          />
                          <label
                            class="form-check-label"
                            for="association"
                          >
                            Association
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input business_type"
                            type="checkbox"

                            value="business_service"
                            id="business_service"
                            onChange={handleCheckboxChange}
                            checked={checkedItems?.includes("business_service")}
                          />
                          <label
                            class="form-check-label"
                            for="business_service"
                          >
                            Business
                            Service(Transportation,finance,travel,Ads.etc)
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input business_type"
                            type="checkbox"

                            value="other"
                            id="other"
                            onChange={handleCheckboxChange}
                            checked={checkedItems?.includes("other")}
                          />
                          <label
                            class="form-check-label"
                            for="other"
                          >
                            Other
                          </label>
                        </div>
                        <p className="mt-3">Select up to 3 Business Type</p>
                      </div>
                    </div>
                    <div className="text_center bottom_bar py-3">
                      <p className="main_button" onClick={(e) => handleVisibOne(e)}>
                        Next
                      </p>
                    </div>
                  </div>
                  <>
                    <div
                      className={showAllMain ? "vendor_rStepMain" : "hide_sec"}
                    >
                      <div className="vendor_steps_navbar">
                        <ul>
                          <li className={showTwo ? "active" : ""} onClick={() => {
                            setShowOne(false);
                            setShowTwo(true);
                            setShowThree(false);
                            setShowFour(false);
                            setShowFive(false);
                            setShowSix(false);
                            setShowSeven(false);
                            setShowEight(false);
                          }}>
                            Basic Company Details
                          </li>
                          <li className={showThree ? "active" : ""} onClick={() => {
                            setShowOne(false);
                            setShowTwo(false);
                            setShowThree(true);
                            setShowFour(false);
                            setShowFive(false);
                            setShowSix(false);
                            setShowSeven(false);
                            setShowEight(false);
                          }}>
                            Manufacturing Capability
                          </li>
                          <li className={showFour ? "active" : ""} onClick={() => {
                            setShowOne(false);
                            setShowTwo(false);
                            setShowThree(false);
                            setShowFour(true);
                            setShowFive(false);
                            setShowSix(false);
                            setShowSeven(false);
                            setShowEight(false);
                          }}>
                            Quality Control
                          </li>
                          <li className={showFive ? "active" : ""} onClick={() => {
                            setShowOne(false);
                            setShowTwo(false);
                            setShowThree(false);
                            setShowFour(false);
                            setShowFive(true);
                            setShowSix(false);
                            setShowSeven(false);
                            setShowEight(false);
                          }}>
                            R&D Capability
                          </li>
                          <li className={showSix ? "active" : ""} onClick={() => {
                            setShowOne(false);
                            setShowTwo(false);
                            setShowThree(false);
                            setShowFour(false);
                            setShowFive(false);
                            setShowSix(true);
                            setShowSeven(false);
                            setShowEight(false);
                          }}>
                            Export Capability
                          </li>
                          <li className={showSeven ? "active" : ""} onClick={() => {
                            setShowOne(false);
                            setShowTwo(false);
                            setShowThree(false);
                            setShowFour(false);
                            setShowFive(false);
                            setShowSix(false);
                            setShowSeven(true);
                            setShowEight(false);
                          }}>
                            Company Introduction
                          </li>
                          {/* <li className={showeight ? "active" : ""} onClick={() => {
                             setShowOne(false);
                            setShowTwo(false);
                            setShowThree(false);
                            setShowFour(false);
                            setShowFive(false);
                            setShowSix(false);
                            setShowSeven(false);
                            setShowEight(true);
                          }}>
                            Company Account Deatils
                          </li> */}
                        </ul>
                      </div>
                      <div className={showTwo ? "vendor_rStepTwo vendor_step" : "hide_sec"}>
                        <div className="input_field">
                          <label for="company_name" class="form-label">
                            Company Name:<span className="astrick">*</span>
                          </label>
                          <input
                            required
                            type="text"
                            class="form-control"
                            id="company_name"
                            placeholder=""
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                          />
                          <span>
                            Please enter the full name of your company
                          </span>
                        </div>
                        <div className="input_field">
                          <label for="registration_location" class="form-label">
                            Location of Registration:
                            <span className="astrick">*</span>
                          </label>
                          <div className="row">
                            <div className="col-sm-6">
                              <span>Country:</span>
                              <CountryDropdown
                                value={locationCountry}
                                onChange={(val) => selectCountry(val)}
                                className="form-control country_select"
                              />
                            </div>
                            <div className="col-sm-6">
                              <span>State:</span>
                              <RegionDropdown
                                country={locationCountry}
                                value={locationState}
                                defaultOptionLabel="Select State"
                                onChange={(val) => selectRegion(val)}
                                className="form-control  country_select"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input_field">
                          <label for="operational_address" class="form-label">
                            Company Operational Address:
                            <span className="astrick">*</span>
                          </label>
                          <div className="row">
                            <div className="col-sm-6">
                              <span>Street</span>
                              <input
                                required
                                type="text"
                                class="form-control"
                                id="street"
                                value={operationalStreet}
                                placeholder=""
                                onChange={(e) =>
                                  setOperationalStreet(e.target.value)
                                }
                              />
                            </div>
                            <div className="col-sm-6">
                              <span>City:</span>
                              <input
                                required
                                type="text"
                                class="form-control"
                                id="city"
                                value={operationalCity}
                                placeholder=""
                                onChange={(e) =>
                                  setOperationalCity(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <span>Country/Region:</span>
                              <CountryDropdown
                                value={operationalCountry}
                                onChange={(val) => setOperationalCountry(val)}
                                className="form-select"
                              />
                            </div>
                            <div className="col-sm-6">
                              <span>Zip/Postal Code:</span>
                              <input
                                required
                                type="number"
                                class="form-control"
                                id="zip_postal_code"
                                value={operationalZipCode}
                                placeholder=""
                                onChange={(e) =>
                                  setOperationalZipCode(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input_field">
                          <label for="main_category" class="form-label">
                            Main category:<span className="astrick">*</span>
                          </label>
                          <select
                            class="form-select"
                            required
                            value={mainCategory}
                            aria-label="Default select example"
                            onChange={(e) => handleMainCatChange(e.target.value)}
                          >
                            <option value="">Select Option</option>
                            {masterCategory?.length > 0 ? (
                              masterCategory?.map((item, i) => (
                                <>
                                  <option value={item.id}>{item.name}</option>
                                </>
                              ))
                            ) : (
                              <>
                                <option value="">Nothing Found</option>
                              </>
                            )}
                          </select>
                        </div>
                        <div className="input_field">
                          <label for="main_category" class="form-label">
                            Category:<span className="astrick">*</span>
                          </label>
                          <select
                            class="form-select"
                            required
                            disabled={!categoriesList?.length > 0}
                            value={category}
                            aria-label="Default select example"
                            onChange={(e) => handleCategoryChange(e.target.value)}
                          >
                            <option value="">Select Option</option>
                            {categoriesList?.length > 0 ? (
                              categoriesList?.map((item, i) => (
                                <>
                                  <option value={item.id}>{item.name}</option>
                                </>
                              ))
                            ) : (
                              <>
                                <option value="">Nothing Found</option>
                              </>
                            )}
                          </select>
                        </div>
                        <div className="input_field">
                          <label for="main_category" class="form-label">
                            Sub Category:<span className="astrick">*</span>
                          </label>
                          <select
                            class="form-select"
                            required
                            disabled={!subCategoriesList?.length > 0}
                            value={subCategory}
                            aria-label="Default select example"
                            onChange={(e) => handleSubCategoryChange(e.target.value)}
                          >
                            <option value="">Select Option</option>
                            {subCategoriesList?.length > 0 ? (
                              subCategoriesList?.map((item, i) => (
                                <>
                                  <option value={item.id}>{item.name}</option>
                                </>
                              ))
                            ) : (
                              <>
                                <option value="">Nothing Found</option>
                              </>
                            )}
                          </select>
                        </div>
                        <div className="input_field">
                          <label for="main_products" class="form-label">
                            Main Products:<span className="astrick">*</span>
                          </label>
                          <select
                            class="form-select"
                            required
                            aria-label="Default select example"
                            multiple
                            disabled={!newProductsList?.length > 0}
                            value={mainProducts}
                            onChange={handleMainProducts}
                          >
                            {newProductsList?.length > 0 ? (
                              newProductsList?.map((item, i) => {
                                return (
                                  <>
                                    <option value={item.id}>{item.name}</option>
                                  </>
                                );
                              })
                            ) : (
                              <>
                                <option value="">Nothing Found</option>
                              </>
                            )}
                          </select>
                        </div>
                        <div className="input_field">
                          <label for="other_products" class="form-label">
                            Other Products:
                            <span className="astrick">*</span>
                          </label>
                          <select
                            class="form-select"
                            required
                            disabled={!otherProductsList?.length > 0}
                            aria-label="Default select example"
                            value={otherProducts}
                            multiple
                            onChange={handleOtherProducts}
                          >
                            {filteredProductsList?.length > 0 ? (
                              filteredProductsList?.map((item, i) => {
                                return (
                                  <>
                                    <option value={item.id}>{item.name}</option>
                                  </>
                                );
                              })
                            ) : (
                              <>
                                <option value="">Nothing Found</option>
                              </>
                            )}
                          </select>
                        </div>
                        <div className="input_field">
                          <label for="company_register_year" class="form-label">
                            Year Company Register:
                            <span className="astrick">*</span>
                          </label>
                          <input
                            required
                            type="number"
                            value={companyRegisterYear}
                            class="form-control"
                            id="company_register_year"
                            placeholder=""
                            onChange={(e) =>
                              setCompanyRegisterYear(e.target.value)
                            }
                          />
                        </div>
                        <div className="input_field">
                          <label for="company_website_url" class="form-label">
                            Company Website url:
                            <span className="astrick">*</span>
                          </label>
                          <input
                            required
                            type="link"
                            value={companyWebsiteUrl}
                            class="form-control"
                            id="company_website_url"
                            placeholder="https://example.com/"
                            onChange={(e) =>
                              setCompanyWebsiteUrl(e.target.value)
                            }
                          />
                        </div>
                        <div className="input_field">
                          <label for="legal_owner" class="form-label">
                            Legal Owner:<span className="astrick">*</span>
                          </label>
                          <input
                            required
                            type="link"
                            class="form-control"
                            value={legalOwner}
                            id="legal_owner"
                            placeholder=""
                            onChange={(e) => setLegalOwner(e.target.value)}
                          />
                        </div>
                        <div className="input_field">
                          <label for="office_size" class="form-label">
                            Office Size:<span className="astrick">*</span>
                          </label>
                          <select
                            class="form-select"
                            required
                            value={officeSize}
                            aria-label="Default select example"
                            onChange={(e) => setOfficeSize(e.target.value)}
                          >
                            <option value="">-- Please select --</option>
                            <option value="1-10">1-10</option>
                            <option value="10-50">10-50</option>
                            <option value="50+">50+</option>
                          </select>
                        </div>
                        <div className="input_field">
                          <label for="company_advantages" class="form-label">
                            Company Advantages:
                            <span className="astrick">*</span>
                          </label>
                          <textarea
                            required
                            value={companyAdvantages}
                            className="form-control"
                            style={{ height: "100px" }}
                            onChange={(e) =>
                              setCompanyAdvantages(e.target.value)
                            }
                          ></textarea>
                          <span>
                            Please briefly describe your company’s advantages.
                            E.g. “We have twenty years experience of product
                            design.”
                          </span>
                        </div>
                        <div className="input_field">
                          <label for="company_advantages" class="form-label">
                            Map Location:
                            <span className="astrick">*</span>
                          </label>
                          <span>
                            Please enter your location or select on map.
                          </span>
                          <Autocomplete
                            className="form-control"
                            apiKey="AIzaSyBJx8whdYVc7PrySCGeSADJZAcz9m_b2Co"
                            libraries="maps,places"
                            onPlaceSelected={(place) => {
                              var location = place.geometry.location;
                              if (location) {
                                setCenter(location);
                                setMarkers([
                                  {
                                    lat: location.lat(),
                                    lng: location.lng()
                                  }
                                ]);
                              }
                            }}
                          />
                          <br></br>
                          <div id="gmap">
                            {isLoaded ? (
                              <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={9}
                                onLoad={onLoad}
                                onClick={onMapClick}
                              >
                                { /* Child components, such as markers, info windows, etc. */}
                                {markers.map((marker) => (
                                  <Marker
                                    position={{
                                      lat: marker.lat,
                                      lng: marker.lng
                                    }} />
                                ))}
                                <></>
                              </GoogleMap>
                            ) : <></>}
                          </div>
                        </div>
                        <div className="d-flex bottom_bar py-3 justify-content-center align-center">
                          <p
                            className="back_button"
                            onClick={handleVisibOneBack}
                          >
                            Back
                          </p>
                          <p
                            className="main_button"
                            style={{ margin: "0px 0px 0px 15px" }}
                            onClick={(e) => handleVisibTwo(e)}
                          >
                            Next
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          showThree
                            ? "vendor_rStepThree vendor_step"
                            : "hide_sec"
                        }
                      >
                        <div className="main_yes_no">
                          <div className="main_label_check">
                            <label>Whether to show production process:</label>
                          </div>
                          <div className="main_label_checkinner">
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="yes"
                                checked={showProductionProcess === "yes"}
                                onChange={(e) => {
                                  {
                                    setShowContentOne(true);
                                  }
                                  {
                                    setShowProductionProcess(e.target.value);
                                  }
                                }}
                              />
                              Yes
                            </label>
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="no"
                                checked={showProductionProcess === "no"}
                                onChange={(e) => {
                                  {
                                    setShowContentOne(false);
                                  }
                                  {
                                    setShowProductionProcess(e.target.value);
                                  }
                                }}
                              />
                              No
                            </label>
                          </div>
                        </div>
                        {showContentOne && (
                          <div class="upload-main">
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  Process name:
                                </label>
                                <input
                                  type="name"
                                  class="form-control"
                                  id="company_name"
                                  value={processName}
                                  onChange={(e) =>
                                    setProcessName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  Process picture:
                                </label>
                                <input
                                  type="file"
                                  class="form-control"
                                  id="company_name"
                                  ref={inputFileRef}
                                  onChangeCapture={onProcessFileChangeCapture}
                                />
                                <div class="placeholder">
                                  <p>Maximum 2M support JPG and PNG format</p>
                                </div>
                                <div
                                  className="Product-thumbnail"
                                  onClick={triggerFile}
                                >
                                  <img
                                    src="../assets/img/default_image.png"
                                    className="w-100"
                                    ref={imgRef}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="label-main mb-3">
                              <div class="label-two">
                                <h2>Process describe:</h2>
                              </div>
                              <textarea
                                cols="30"
                                rows="5"
                                value={processDescribe}
                                onChange={(e) =>
                                  setProcessDescribe(e.target.value)
                                }
                              ></textarea>
                            </div>
                          </div>
                        )}

                        {/* ------------Two------------- */}

                        <div className="main_yes_no">
                          <div className="main_label_check">
                            <label>Whether to show production equipment:</label>
                          </div>
                          <div className="main_label_checkinner">
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="yes"
                                checked={showProductionEquipment === "yes"}
                                onChange={(e) => {
                                  {
                                    setShowContentTwo(true);
                                  }
                                  {
                                    setShowProductionEquipment(e.target.value);
                                  }
                                }}
                              />
                              Yes
                            </label>
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="no"
                                checked={showProductionEquipment === "no"}
                                onChange={(e) => {
                                  {
                                    setShowContentTwo(false);
                                  }
                                  {
                                    setShowProductionEquipment(e.target.value);
                                  }
                                }}
                              />
                              No
                            </label>
                          </div>
                        </div>
                        {showContentTwo && (
                          <div class="upload-main">
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  Equipment Name:
                                </label>
                                <input
                                  type="name"
                                  class="form-control"
                                  id="company_name"
                                  value={equipmentName}
                                  onChange={(e) =>
                                    setEquipmentName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  Equipment Model:
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  value={equipmentModel}
                                  id="company_name"
                                  onChange={(e) =>
                                    setEquipmentModel(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  Equipment Quantity:
                                </label>
                                <input
                                  type="number"
                                  class="form-control"
                                  id="company_name"
                                  value={equipmentQuantity}
                                  onChange={(e) =>
                                    setEquipmentQuantity(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="main_yes_no">
                          <div className="main_label_check">
                            <label>Whether to show Production line:</label>
                          </div>
                          <div className="main_label_checkinner">
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="yes"
                                checked={showProductionLine === "yes"}
                                onChange={(e) => {
                                  {
                                    setShowContentThree(true);
                                  }
                                  {
                                    setShowProductionLine(e.target.value);
                                  }
                                }}
                              />
                              Yes
                            </label>
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="no"
                                checked={showProductionLine === "no"}
                                onChange={(e) => {
                                  {
                                    setShowContentThree(false);
                                  }
                                  {
                                    setShowProductionLine(e.target.value);
                                  }
                                }}
                              />
                              No
                            </label>
                          </div>
                        </div>

                        {/* ------------Three-------------- */}

                        {showContentThree && (
                          <div class="upload-main">
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  Production Line name:
                                </label>
                                <input
                                  type="name"
                                  class="form-control"
                                  id="company_name"
                                  value={productionLineName}
                                  onChange={(e) =>
                                    setProductionLineName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  Supervisor Number:
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="company_name"
                                  value={supervisorNumber}
                                  onChange={(e) =>
                                    setSupervisorNumber(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  Number of Operators:
                                </label>
                                <input
                                  type="number"
                                  class="form-control"
                                  id="company_name"
                                  value={numberofOperators}
                                  onChange={(e) =>
                                    setNumberofOperators(e.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  QC/QA Number:
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="company_name"
                                  value={qcQaNumber}
                                  onChange={(e) =>
                                    setQcQaNumber(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="input_field">
                          <label for="company_name" class="form-label">
                            Factory Location:
                            <span className="astrick">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="company_name"
                            placeholder=""
                            value={factorylocation}
                            onChange={(e) => setFactorylocation(e.target.value)}
                          />
                        </div>
                        <div className="input_field">
                          <div className="row">
                            <div className="col-sm-12">
                              <label for="company_name" class="form-label">
                                Factory Size:
                                <span className="astrick">*</span>
                              </label>
                              <select
                                onChange={(e) => setFactorySize(e.target.value)}
                                value={factorySize}
                                class="form-select"
                                aria-label="Default select example"
                              >
                                <option value="">-- Please select --</option>
                                <option value="1-10">1-10</option>
                                <option value="10-50">10-50</option>
                                <option value="50+">50+</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="input_field">
                          <label for="company_name" class="form-label">
                            Contract Manufacturing:
                            <span className="astrick">*</span>
                          </label>
                          <div class="check-main">
                            <div class="form-check me-1 inner_me">
                              <input
                                class="form-check-input common_innerinput contract_manufacturing"
                                type="checkbox"
                                value="oem_service_offered"
                                id="oem_service_offered"
                                onChange={handleContractManufacturing}
                                checked={manufactureContract?.includes(
                                  "oem_service_offered"
                                )}
                              />
                              <label
                                class="form-check-label"
                                for="oem_service_offered"
                              >
                                OEM Service Offered
                              </label>
                            </div>
                            <div class="form-check me-1 inner_me">
                              <input
                                class="form-check-input common_innerinput  contract_manufacturing"
                                type="checkbox"
                                id="design_service_offered"
                                value="design_service_offered"
                                onChange={handleContractManufacturing}
                                checked={manufactureContract?.includes(
                                  "design_service_offered"
                                )}
                              />
                              <label
                                class="form-check-label"
                                for="design_service_offered"
                              >
                                Design Service Offered
                              </label>
                            </div>
                            <div class="form-check me-1 inner_me">
                              <input
                                class="form-check-input common_innerinput  contract_manufacturing"
                                type="checkbox"
                                id="buyer_label_offered"
                                value="buyer_label_offered"
                                onChange={handleContractManufacturing}
                                checked={manufactureContract?.includes(
                                  "buyer_label_offered"
                                )}
                              />
                              <label
                                class="form-check-label"
                                for="buyer_label_offered"
                              >
                                Buyer Label Offered
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="input_field">
                          <div className="row">
                            <div className="col-sm-12">
                              <label for="company_name" class="form-label">
                                No.of QC Staff:
                                <span className="astrick">*</span>
                              </label>
                              <select
                                onChange={(e) => setQCStaff(e.target.value)}
                                value={qCStaff}
                                class="form-select"
                                aria-label="Default select example"
                              >
                                <option value="">-- Please select --</option>

                                <option value="united states">One</option>
                                <option value="canada">Two</option>
                                <option value="India">Three</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="input_field">
                          <div className="row">
                            <div className="col-sm-12">
                              <label for="company_name" class="form-label">
                                No.of R & D Staff:
                                <span className="astrick">*</span>
                              </label>
                              <select
                                onChange={(e) => setRDStaff(e.target.value)}
                                value={rDStaff}
                                class="form-select"
                                aria-label="Default select example"
                              >
                                <option value="">-- Please select --</option>

                                <option value="united states">One</option>
                                <option value="canada">Two</option>
                                <option value="India">Three</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="input_field">
                          <div className="row">
                            <div className="col-sm-12">
                              <label for="company_name" class="form-label">
                                No.of Production Lines:
                                <span className="astrick">*</span>
                              </label>
                              <select
                                onChange={(e) =>
                                  setProductionLines(e.target.value)
                                }
                                value={productionLines}
                                class="form-select"
                                aria-label="Default select example"
                              >
                                <option value="">-- Please select --</option>

                                <option value="united states">One</option>
                                <option value="canada">Two</option>
                                <option value="India">Three</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="input_field">
                          <div className="row">
                            <div className="col-sm-12">
                              <label for="company_name" class="form-label">
                                Annual Output Value:
                                <span className="astrick">*</span>
                              </label>
                              <select
                                onChange={(e) =>
                                  setAnnualOutputValue(e.target.value)
                                }
                                value={annualOutputValue}
                                class="form-select"
                                aria-label="Default select example"
                              >
                                <option value="">-- Please select --</option>

                                <option value="united states">One</option>
                                <option value="canada">Two</option>
                                <option value="India">Three</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="main_yes_no">
                          <div className="main_label_check">
                            <label>
                              Add information about your annual production
                              capacity:
                            </label>
                          </div>
                          <div className="main_label_checkinner">
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="yes"
                                checked={annualProductionCapacity === "yes"}
                                onChange={(e) => {
                                  {
                                    setShowContentFour(true);
                                  }
                                  {
                                    setAnnualProductionCapacity(e.target.value);
                                  }
                                }}
                              />
                              Yes
                            </label>
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="no"
                                checked={annualProductionCapacity === "no"}
                                onChange={(e) => {
                                  {
                                    setShowContentFour(false);
                                  }
                                  {
                                    setAnnualProductionCapacity(e.target.value);
                                  }
                                }}
                              />
                              No
                            </label>
                          </div>
                        </div>

                        {/* ------------Four-------------- */}
                        {showContentFour && (
                          <div class="upload-main">
                            <h6>Production Details:</h6>
                            <div className="input_field">
                              <div class="label-main mb-3">
                                <div class="label-two">
                                  <label for="company_name" class="form-label">
                                    Product Name:
                                  </label>
                                </div>
                                <input
                                  type="text"
                                  class="form-control "
                                  value={productName}
                                  onChange={(e) =>
                                    setProductName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="input_field">
                              <div class="label-main mb-3">
                                <div class="label-two">
                                  <label for="company_name" class="form-label">
                                    Units Produced (Previous Year):
                                  </label>
                                </div>
                                <div class="d-flex">
                                  <input
                                    type="text"
                                    class="form-control  me-2"
                                    value={unitsProduced}
                                    onChange={(e) =>
                                      setUnitsProduced(e.target.value)
                                    }
                                  />
                                  <select
                                    class="form-select "
                                    aria-label="Default select example"
                                    value={unitsProducedUnitsType}
                                    onChange={(e) =>
                                      setUnitsProducedUnitsType(e.target.value)
                                    }
                                  >
                                    <option value="">Select Unit Type</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="input_field">
                              <div class="label-main">
                                <div class="label-two">
                                  <label for="company_name" class="form-label">
                                    Highest Ever Annual Output:
                                  </label>
                                </div>
                                <div class="d-flex">
                                  <input
                                    type="text"
                                    class="form-control  me-2"
                                    onChange={(e) =>
                                      setHighestEverAnnual(e.target.value)
                                    }
                                    value={highestEverAnnual}
                                  />
                                  <select
                                    class="form-select "
                                    aria-label="Default select example"
                                    value={highestEverAnnualUnitsType}
                                    onChange={(e) =>
                                      setHighestEverAnnualUnitsType(
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="">Select Unit Type</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="d-flex bottom_bar py-3 justify-content-center align-center">
                          <p
                            className="back_button"
                            onClick={handleVisibTwoBack}
                          >
                            Back
                          </p>
                          <p
                            className="main_button"
                            style={{ margin: "0px 0px 0px 15px" }}
                            onClick={(e) => handleVisibThree(e)}
                          >
                            Next
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          showFour
                            ? "vendor_rStepThree vendor_step"
                            : "hide_sec"
                        }
                      >
                        <div className="main_yes_no">
                          <div className="main_label_check">
                            <label>
                              Whether to demonstrate the quality control
                              process:
                            </label>
                          </div>
                          <div className="main_label_checkinner">
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="yes"
                                checked={qualityControlProcess === "yes"}
                                onChange={(e) => {
                                  setShowContentFive(true);
                                  setQualityControlProcess(e.target.value);
                                }}
                              />
                              Yes
                            </label>
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="no"
                                checked={qualityControlProcess === "no"}
                                onChange={(e) => {
                                  setShowContentFive(false);
                                  setQualityControlProcess(e.target.value);
                                }}
                              />
                              No
                            </label>
                          </div>
                        </div>

                        {showContentFive && (
                          <div class="upload-main">
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  Process name:
                                </label>
                                <input
                                  type="name"
                                  class="form-control"
                                  id="company_name"
                                  value={qualityprocessName}
                                  onChange={(e) =>
                                    setQualityProcessName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  Process picture:
                                </label>
                                <input
                                  type="file"
                                  class="form-control"
                                  id="company_name"
                                  ref={qualityinputFileRef}
                                  onChangeCapture={onQualityFileChangeCapture}
                                />
                                <div class="placeholder">
                                  <p>Maximum 2M support JPG and PNG format</p>
                                </div>
                                <div
                                  className="Product-thumbnail"
                                  onClick={qualitytriggerFile}
                                >
                                  <img
                                    src="../assets/img/default_image.png"
                                    className="w-100"
                                    ref={qualityimgRef}
                                    alt="placehoder"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="label-main mb-3">
                              <div class="label-two">
                                <h2>Process describe:</h2>
                              </div>
                              <textarea
                                cols="30"
                                rows="5"
                                value={qualityprocessDescribe}
                                onChange={(e) =>
                                  setQualityProcessDescribe(e.target.value)
                                }
                              ></textarea>
                            </div>
                          </div>
                        )}

                        {/* ------------Two------------- */}

                        <div className="main_yes_no">
                          <div className="main_label_check">
                            <label>Whether to display testing equipment:</label>
                          </div>
                          <div className="main_label_checkinner">
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="yes"
                                checked={displayTestingEquipment === "yes"}
                                onChange={(e) => {
                                  setShowContentSix(true);
                                  setDisplayTestingEquipment(e.target.value);
                                }}
                              />
                              Yes
                            </label>
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="no"
                                checked={displayTestingEquipment === "no"}
                                onChange={(e) => {
                                  setShowContentSix(false);
                                  setDisplayTestingEquipment(e.target.value);
                                }}
                              />
                              No
                            </label>
                          </div>
                        </div>
                        {showContentSix && (
                          <div class="upload-main">
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  Equipment Name:
                                </label>
                                <input
                                  type="name"
                                  class="form-control"
                                  id="company_name"
                                  value={qualityequipmentName}
                                  onChange={(e) =>
                                    setQualityEquipmentName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  Equipment Model:
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  value={qualityequipmentModel}
                                  id="company_name"
                                  onChange={(e) =>
                                    setQualityEquipmentModel(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  Equipment Quantity:
                                </label>
                                <input
                                  type="number"
                                  class="form-control"
                                  id="company_name"
                                  value={qualityequipmentQuantity}
                                  onChange={(e) =>
                                    setQualityEquipmentQuantity(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="d-flex bottom_bar py-3 justify-content-center align-center">
                          <p
                            className="back_button"
                            onClick={handleVisibThreeBack}
                          >
                            Back
                          </p>
                          <p
                            className="main_button"
                            style={{ margin: "0px 0px 0px 15px" }}
                            onClick={(e) => handleVisibFour(e)}
                          >
                            Next
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          showFive ? "vendor_rStepFour vendor_step" : "hide_sec"
                        }
                      >
                        <div className="main_yes_no">
                          <div className="main_label_check">
                            <label>Whether to show R & D process:</label>
                          </div>
                          <div className="main_label_checkinner">
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="yes"
                                checked={showRDProcess === "yes"}
                                onChange={(e) => {
                                  {
                                    setShowContentEight(true);
                                  }
                                  {
                                    setShowRDProcess(e.target.value);
                                  }
                                }}
                              />
                              Yes
                            </label>
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="no"
                                checked={showRDProcess === "no"}
                                onChange={(e) => {
                                  {
                                    setShowContentEight(false);
                                  }
                                  {
                                    setShowRDProcess(e.target.value);
                                  }
                                }}
                              />
                              No
                            </label>
                          </div>
                        </div>

                        {showContentEight && (
                          <div class="upload-main">
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  Process name:
                                </label>
                                <input
                                  type="name"
                                  class="form-control"
                                  id="company_name"
                                  value={rdProcessName}
                                  onChange={(e) =>
                                    setRDrocessName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="company_name" class="form-label">
                                  Process picture:
                                </label>
                                <input
                                  type="file"
                                  class="form-control"
                                  id="company_name"
                                  ref={rdinputFileRef}
                                  onChangeCapture={onRDFileChangeCapture}
                                />
                                <div class="placeholder">
                                  <p>Maximum 2M support JPG and PNG format</p>
                                </div>
                                <div
                                  className="Product-thumbnail"
                                  onClick={rdtriggerFile}
                                >
                                  <img
                                    src="../assets/img/default_image.png"
                                    className="w-100"
                                    ref={rdimgRef}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="label-main mb-3">
                              <div class="label-two">
                                <h2>Process describe:</h2>
                              </div>
                              <textarea
                                cols="30"
                                rows="5"
                                value={rdProcessDescribe}
                                onChange={(e) =>
                                  setRDrocessDescribe(e.target.value)
                                }
                              ></textarea>
                            </div>
                          </div>
                        )}
                        <div className="d-flex bottom_bar py-3 justify-content-center align-center">
                          <p
                            className="back_button"
                            onClick={handleVisibFourBack}
                          >
                            Back
                          </p>
                          <p
                            className="main_button"
                            style={{ margin: "0px 0px 0px 15px" }}
                            onClick={(e) => handleVisibFive(e)}
                          >
                            Next
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          showSix ? "vendor_rStepFive vendor_step" : "hide_sec"
                        }
                      >
                        <div className="input_field">
                          <label for="annual_revenue" class="form-label">
                            Total Annual Revenue:
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="annual_revenue"
                            placeholder=""
                            value={annualRevenue}
                            onChange={(e) => setAnnualRevenue(e.target.value)}
                          />
                        </div>
                        <div className="input_field">
                          <label for="export_percentage" class="form-label">
                            Export Percentage:
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="export_percentage"
                            placeholder=""
                            value={exportPercentage}
                            onChange={(e) =>
                              setExportPercentage(e.target.value)
                            }
                          />
                        </div>
                        <div className="input_field">
                          <label
                            for="main_marks_and_distribution"
                            class="form-label"
                          >
                            Main Marks and Distribution:
                          </label>
                          <div className="row">
                            <div className="col-sm-4 mb-3">
                              <input
                                type="number"
                                class="form-control"
                                id="main_marksNorthAmerica"
                                placeholder=""
                                value={mainMarksNorthAmerica}
                                onChange={(e) =>
                                  setMainMarksNorthAmerica(e.target.value)
                                }
                              />
                              <span>% North America</span>
                            </div>
                            <div className="col-sm-4 mb-3">
                              <input
                                type="number"
                                class="form-control"
                                id="main_marksSouthAmerica"
                                placeholder=""
                                value={mainMarksSouthAmerica}
                                onChange={(e) =>
                                  setMainMarksSouthAmerica(e.target.value)
                                }
                              />
                              <span>% South America</span>
                            </div>
                            <div className="col-sm-4 mb-3">
                              <input
                                type="number"
                                class="form-control"
                                id="main_marksEasternEurope"
                                placeholder=""
                                value={mainMarksEasternEurope}
                                onChange={(e) =>
                                  setMainMarksEasternEurope(e.target.value)
                                }
                              />
                              <span>% Eastern Europe</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-4 mb-3">
                              <input
                                type="number"
                                class="form-control"
                                id="main_marksSouthEastAsia"
                                placeholder=""
                                value={mainMarksSouthEastAsia}
                                onChange={(e) =>
                                  setMainMarksSouthEastAsia(e.target.value)
                                }
                              />
                              <span>% Southeast Asia</span>
                            </div>
                            <div className="col-sm-4 mb-3">
                              <input
                                type="number"
                                class="form-control"
                                id="main_marksAfrica"
                                placeholder=""
                                value={mainMarksAfrica}
                                onChange={(e) =>
                                  setMainMarksAfrica(e.target.value)
                                }
                              />
                              <span>% Africa</span>
                            </div>
                            <div className="col-sm-4 mb-3">
                              <input
                                type="number"
                                class="form-control"
                                id="main_marksOceania"
                                placeholder=""
                                value={mainMarksOceania}
                                onChange={(e) =>
                                  setMainMarksOceania(e.target.value)
                                }
                              />
                              <span>% Oceania</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-4 mb-3">
                              <input
                                type="number"
                                class="form-control"
                                id="main_marksMidEast"
                                placeholder=""
                                value={mainMarksMidEast}
                                onChange={(e) =>
                                  setMainMarksMidEast(e.target.value)
                                }
                              />
                              <span>% Mid East</span>
                            </div>
                            <div className="col-sm-4 mb-3">
                              <input
                                type="number"
                                class="form-control"
                                id="main_marksEasternAsia"
                                placeholder=""
                                value={mainMarksEasternAsia}
                                onChange={(e) =>
                                  setMainMarksEasternAsia(e.target.value)
                                }
                              />
                              <span>% Eastern Asia</span>
                            </div>
                            <div className="col-sm-4 mb-3">
                              <input
                                type="number"
                                class="form-control"
                                id="main_marksWesternEurope"
                                placeholder=""
                                value={mainMarksWesternEurope}
                                onChange={(e) =>
                                  setMainMarksWesternEurope(e.target.value)
                                }
                              />
                              <span>% Western Europe</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-4 mb-3">
                              <input
                                type="number"
                                class="form-control"
                                id="main_marksCentralAmerica"
                                placeholder=""
                                value={mainMarksCentralAmerica}
                                onChange={(e) =>
                                  setMainMarksCentralAmerica(e.target.value)
                                }
                              />
                              <span>% Central America</span>
                            </div>
                            <div className="col-sm-4 mb-3">
                              <input
                                type="number"
                                class="form-control"
                                id="main_marksNorthernEurope"
                                placeholder=""
                                value={mainMarksNorthernEurope}
                                onChange={(e) =>
                                  setMainMarksNorthernEurope(e.target.value)
                                }
                              />
                              <span>% Northern Europe</span>
                            </div>
                            <div className="col-sm-4 mb-3">
                              <input
                                type="number"
                                class="form-control"
                                id="main_marksSouthernEurope"
                                placeholder=""
                                value={mainMarksSouthernEurope}
                                onChange={(e) =>
                                  setMainMarksSouthernEurope(e.target.value)
                                }
                              />
                              <span>% Southern Europe</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-4 mb-3">
                              <input
                                type="number"
                                class="form-control"
                                id="main_marksSouthAsia"
                                placeholder=""
                                value={mainMarksSouthAsia}
                                onChange={(e) =>
                                  setMainMarksSouthAsia(e.target.value)
                                }
                              />
                              <span>% South Asia</span>
                            </div>
                            <div className="col-sm-4 mb-3">
                              <input
                                type="number"
                                class="form-control"
                                id="main_marksDomesticMarket"
                                placeholder=""
                                value={mainMarksDomesticMarket}
                                onChange={(e) =>
                                  setMainMarksDomesticMarket(e.target.value)
                                }
                              />
                              <span>% Domestic Market</span>
                            </div>
                          </div>
                        </div>
                        <div className="input_field">
                          <label for="export_startYear" class="form-label">
                            Year when your company started exporting:
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="export_startYear"
                            placeholder=""
                            value={exportStartYear}
                            onChange={(e) => setExportStartYear(e.target.value)}
                          />
                        </div>
                        <div className="input_field">
                          <div className="main_yes_no">
                            <div className="main_label_check">
                              <label>
                                Whether add customer case:
                              </label>
                            </div>
                            <div className="main_label_checkinner">
                              <label class="form-check-label">
                                <input
                                  type="radio"
                                  class="form-check-input"
                                  value="yes"
                                  checked={addCustomerCase === "yes"}
                                  onChange={(e) => {
                                    {
                                      setShowContentSeven(true);
                                    }
                                    {
                                      setAddCustomerCase(e.target.value);
                                    }
                                  }}
                                />
                                Yes
                              </label>
                              <label class="form-check-label">
                                <input
                                  type="radio"
                                  class="form-check-input"
                                  value="no"
                                  checked={addCustomerCase === "no"}
                                  onChange={(e) => {
                                    {
                                      setShowContentSeven(false);
                                    }
                                    {
                                      setAddCustomerCase(e.target.value);
                                    }
                                  }}
                                />
                                No
                              </label>
                            </div>
                          </div>

                          {showContentSeven && (
                            <div class="upload-main">
                              <div class="label-main mb-3">
                                <div className="input_field">
                                  <label
                                    for="project_customerName"
                                    class="form-label"
                                  >
                                    Project / Customer Name:
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="project_customerName"
                                    value={customerCaseProjectName}
                                    onChange={(e) =>
                                      setCustomerCaseProjectName(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div class="label-main mb-3">
                                <div className="input_field">
                                  <label
                                    for="customer_Country"
                                    class="form-label"
                                  >
                                    Customer’s Country/Region:
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="customer_Country"
                                    value={customerCaseCountry}
                                    onChange={(e) =>
                                      setCustomerCaseCountry(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div class="label-main mb-3">
                                <div className="input_field">
                                  <label
                                    for="product_youSupply"
                                    class="form-label"
                                  >
                                    Products You Supply To Customer:
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="product_youSupply"
                                    value={customerCaseProducts}
                                    onChange={(e) =>
                                      setCustomerCaseProducts(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div class="label-main mb-3">
                                <div className="input_field">
                                  <label
                                    for="annual_turnover"
                                    class="form-label"
                                  >
                                    Annual Turnover ($US):
                                  </label>
                                  <input
                                    type="number"
                                    class="form-control"
                                    id="annual_turnover"
                                    value={customerCaseAnnualTurnover}
                                    onChange={(e) =>
                                      setCustomerCaseAnnualTurnover(
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="input_field">
                          <label
                            for="no_of_employees_in_trade"
                            class="form-label"
                          >
                            No.of Employees in Trade Department:
                          </label>
                          <select
                            class="form-select"
                            value={noOfEmployeesTrade}
                            aria-label="Default select example"
                            onChange={(e) =>
                              setNoOfEmployeesTrade(e.target.value)
                            }
                          >
                            <option value="">Select Option</option>
                            <option value="1">Test Category</option>
                            <option value="1">Test Category</option>
                            <option value="1">Test Category</option>
                          </select>
                        </div>
                        <div className="input_field">
                          <label for="nearest_port" class="form-label">
                            Nearest Port:
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="nearest_port"
                            placeholder=""
                            value={nearestPort}
                            onChange={(e) => setNearestPort(e.target.value)}
                          />
                        </div>
                        <div className="input_field">
                          <label for="average_lead_time" class="form-label">
                            Average Lead Time:
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="average_lead_time"
                            placeholder=""
                            value={averageLeadTime}
                            onChange={(e) => setAverageLeadTime(e.target.value)}
                          />
                        </div>
                        <div className="input_field">
                          <label for="overseas_office" class="form-label">
                            Does your company have an overseas office?:
                          </label>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="overseas_officeYes"
                              value="0"
                              checked={companyOverseasOffice === 0}
                              onChange={(e) =>
                                setCompanyOverseasOffice(e.target.value)
                              }
                            />
                            <label
                              class="form-check-label m-0"
                              for="overseas_officeYes"
                            >
                              Yes
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="overseas_officeNo"
                              value="1"
                              checked={companyOverseasOffice === 1}
                              onChange={(e) =>
                                setCompanyOverseasOffice(e.target.value)
                              }
                            />
                            <label
                              class="form-check-label m-0"
                              for="overseas_officeNo"
                            >
                              No
                            </label>
                          </div>
                        </div>
                        <div className="input_field">
                          <label
                            for="main_marks_and_distribution"
                            class="form-label"
                          >
                            Accepted Delivery Terms:
                          </label>
                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-check">
                                <input
                                  class="form-check-input delivery_terms"
                                  type="checkbox"
                                  value="fob"
                                  id="fob"
                                  onChange={handleAcceptedDTerms}
                                  checked={acceptedDeliveryTerms?.includes(
                                    "fob"
                                  )}
                                />
                                <label class="form-check-label m-0" for="fob">
                                  FOB
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input delivery_terms"
                                  type="checkbox"
                                  value="exw"
                                  id="exw"
                                  onChange={handleAcceptedDTerms}
                                  checked={acceptedDeliveryTerms?.includes(
                                    "exw"
                                  )}
                                />
                                <label class="form-check-label m-0" for="exw">
                                  EXW
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input delivery_terms"
                                  type="checkbox"
                                  value="fca"
                                  id="fca"
                                  onChange={handleAcceptedDTerms}
                                  checked={acceptedDeliveryTerms?.includes(
                                    "fca"
                                  )}
                                />
                                <label class="form-check-label m-0" for="fca">
                                  FCA
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input delivery_terms"
                                  type="checkbox"
                                  value="ddp"
                                  id="ddp"
                                  onChange={handleAcceptedDTerms}
                                  checked={acceptedDeliveryTerms?.includes(
                                    "ddp"
                                  )}
                                />
                                <label class="form-check-label m-0" for="ddp">
                                  DDP
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input delivery_terms"
                                  type="checkbox"
                                  value="daf"
                                  id="daf"
                                  onChange={handleAcceptedDTerms}
                                  checked={acceptedDeliveryTerms?.includes(
                                    "daf"
                                  )}
                                />
                                <label class="form-check-label m-0" for="daf">
                                  DAF
                                </label>
                              </div>
                            </div>

                            <div className="col-sm-4">
                              <div class="form-check">
                                <input
                                  class="form-check-input delivery_terms"
                                  type="checkbox"
                                  value="cfr"
                                  id="cfr"
                                  onChange={handleAcceptedDTerms}
                                  checked={acceptedDeliveryTerms?.includes(
                                    "cfr"
                                  )}
                                />
                                <label class="form-check-label m-0" for="cfr">
                                  CFR
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input delivery_terms"
                                  type="checkbox"
                                  value="fas"
                                  id="fas"
                                  onChange={handleAcceptedDTerms}
                                  checked={acceptedDeliveryTerms?.includes(
                                    "fas"
                                  )}
                                />
                                <label class="form-check-label m-0" for="fas">
                                  FAS
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input delivery_terms"
                                  type="checkbox"
                                  value="cpt"
                                  id="cpt"
                                  onChange={handleAcceptedDTerms}
                                  checked={acceptedDeliveryTerms?.includes(
                                    "cpt"
                                  )}
                                />
                                <label class="form-check-label m-0" for="cpt">
                                  CPT
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input delivery_terms"
                                  type="checkbox"
                                  value="ddu"
                                  id="ddu"
                                  onChange={handleAcceptedDTerms}
                                  checked={acceptedDeliveryTerms?.includes(
                                    "ddu"
                                  )}
                                />
                                <label class="form-check-label m-0" for="ddu">
                                  DDU
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input delivery_terms"
                                  type="checkbox"
                                  value="des"
                                  id="des"
                                  onChange={handleAcceptedDTerms}
                                  checked={acceptedDeliveryTerms?.includes(
                                    "des"
                                  )}
                                />
                                <label class="form-check-label m-0" for="des">
                                  DES
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-check">
                                <input
                                  class="form-check-input delivery_terms"
                                  type="checkbox"
                                  value="cif"
                                  id="cif"
                                  onChange={handleAcceptedDTerms}
                                  checked={acceptedDeliveryTerms?.includes(
                                    "cif"
                                  )}
                                />
                                <label class="form-check-label m-0" for="cif">
                                  CIF
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input delivery_terms"
                                  type="checkbox"
                                  value="cip"
                                  id="cip"
                                  onChange={handleAcceptedDTerms}
                                  checked={acceptedDeliveryTerms?.includes(
                                    "cip"
                                  )}
                                />
                                <label class="form-check-label m-0" for="cip">
                                  CIP
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input delivery_terms"
                                  type="checkbox"
                                  value="deq"
                                  id="deq"
                                  onChange={handleAcceptedDTerms}
                                  checked={acceptedDeliveryTerms?.includes(
                                    "deq"
                                  )}
                                />
                                <label class="form-check-label m-0" for="deq">
                                  DEQ
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input delivery_terms"
                                  type="checkbox"
                                  value="expressDelivery"
                                  id="expressDelivery"
                                  onChange={handleAcceptedDTerms}
                                  checked={acceptedDeliveryTerms?.includes(
                                    "expressDelivery"
                                  )}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="expressDelivery"
                                >
                                  Express Delivery
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="input_field">
                          <label
                            for="main_marks_and_distribution"
                            class="form-label"
                          >
                            Accepted Payment Currency:
                          </label>
                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-check">
                                <input
                                  class="form-check-input payment_currency"
                                  type="checkbox"
                                  value="usd"
                                  id="usd"
                                  onChange={handlePaymentMethods}
                                  checked={acceptedPaymentMethods?.includes(
                                    "usd"
                                  )}
                                />
                                <label class="form-check-label m-0" for="usd">
                                  USD
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input payment_currency"
                                  type="checkbox"
                                  value="cad"
                                  id="cad"
                                  onChange={handlePaymentMethods}
                                  checked={acceptedPaymentMethods?.includes(
                                    "cad"
                                  )}
                                />
                                <label class="form-check-label m-0" for="cad">
                                  CAD
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input payment_currency"
                                  type="checkbox"
                                  value="gbp"
                                  id="gbp"
                                  onChange={handlePaymentMethods}
                                  checked={acceptedPaymentMethods?.includes(
                                    "gbp"
                                  )}
                                />
                                <label class="form-check-label m-0" for="gbp">
                                  GBP
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-check">
                                <input
                                  class="form-check-input payment_currency"
                                  type="checkbox"
                                  value="eur"
                                  id="eur"
                                  onChange={handlePaymentMethods}
                                  checked={acceptedPaymentMethods?.includes(
                                    "eur"
                                  )}
                                />
                                <label class="form-check-label m-0" for="eur">
                                  EUR
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input payment_currency"
                                  type="checkbox"
                                  value="aud"
                                  id="aud"
                                  onChange={handlePaymentMethods}
                                  checked={acceptedPaymentMethods?.includes(
                                    "aud"
                                  )}
                                />
                                <label class="form-check-label m-0" for="aud">
                                  AUD
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input payment_currency"
                                  type="checkbox"
                                  value="cny"
                                  id="cny"
                                  onChange={handlePaymentMethods}
                                  checked={acceptedPaymentMethods?.includes(
                                    "cny"
                                  )}
                                />
                                <label class="form-check-label m-0" for="cny">
                                  CNY
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-check">
                                <input
                                  class="form-check-input payment_currency"
                                  type="checkbox"
                                  value="jpy"
                                  id="jpy"
                                  onChange={handlePaymentMethods}
                                  checked={acceptedPaymentMethods?.includes(
                                    "jpy"
                                  )}
                                />
                                <label class="form-check-label m-0" for="jpy">
                                  JPY
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input payment_currency"
                                  type="checkbox"
                                  value="hkd"
                                  id="hkd"
                                  onChange={handlePaymentMethods}
                                  checked={acceptedPaymentMethods?.includes(
                                    "hkd"
                                  )}
                                />
                                <label class="form-check-label m-0" for="hkd">
                                  HKD
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input payment_currency"
                                  type="checkbox"
                                  value="chf"
                                  id="chf"
                                  onChange={handlePaymentMethods}
                                  checked={acceptedPaymentMethods?.includes(
                                    "chf"
                                  )}
                                />
                                <label class="form-check-label m-0" for="chf">
                                  CHF
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="input_field">
                          <label
                            for="main_marks_and_distribution"
                            class="form-label"
                          >
                            Accepted Payment Type:
                          </label>
                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-check">
                                <input
                                  class="form-check-input accepted_paymentType"
                                  type="checkbox"
                                  value="t/t"
                                  id="t/t"
                                  onChange={handlePaymentTypes}
                                  checked={acceptedPaymentTypes?.includes("t/t")}
                                />
                                <label class="form-check-label m-0" for="t/t">
                                  T/T
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input accepted_paymentType"
                                  type="checkbox"
                                  value="moneyGram"
                                  id="moneyGram"
                                  onChange={handlePaymentTypes}
                                  checked={acceptedPaymentTypes?.includes(
                                    "moneyGram"
                                  )}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="moneyGram"
                                >
                                  MoneyGram
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input accepted_paymentType"
                                  type="checkbox"
                                  value="westernUnion"
                                  id="westernUnion"
                                  onChange={handlePaymentTypes}
                                  checked={acceptedPaymentTypes?.includes(
                                    "westernUnion"
                                  )}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="westernUnion"
                                >
                                  Western Union
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-check">
                                <input
                                  class="form-check-input accepted_paymentType"
                                  type="checkbox"
                                  value="l/c"
                                  id="l/c"
                                  onChange={handlePaymentTypes}
                                  checked={acceptedPaymentTypes?.includes("l/c")}
                                />
                                <label class="form-check-label m-0" for="l/c">
                                  L/C
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input accepted_paymentType"
                                  type="checkbox"
                                  value="creditCard"
                                  id="creditCard"
                                  onChange={handlePaymentTypes}
                                  checked={acceptedPaymentTypes?.includes(
                                    "creditCard"
                                  )}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="creditCard"
                                >
                                  Credit Card
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input accepted_paymentType"
                                  type="checkbox"
                                  value="cash"
                                  id="cash"
                                  onChange={handlePaymentTypes}
                                  checked={acceptedPaymentTypes?.includes(
                                    "cash"
                                  )}
                                />
                                <label class="form-check-label m-0" for="cash">
                                  Cash
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-check">
                                <input
                                  class="form-check-input accepted_paymentType"
                                  type="checkbox"
                                  value="d/p_d/a"
                                  id="d/p_d/a"
                                  onChange={handlePaymentTypes}
                                  checked={acceptedPaymentTypes?.includes(
                                    "d/p_d/a"
                                  )}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="d/p_d/a"
                                >
                                  D/P D/A
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input accepted_paymentType"
                                  type="checkbox"
                                  value="paypal"
                                  id="paypal"
                                  onChange={handlePaymentTypes}
                                  checked={acceptedPaymentTypes?.includes(
                                    "paypal"
                                  )}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="paypal"
                                >
                                  Paypal
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input accepted_paymentType"
                                  type="checkbox"
                                  value="escrow"
                                  id="escrow"
                                  onChange={handlePaymentTypes}
                                  checked={acceptedPaymentTypes?.includes(
                                    "escrow"
                                  )}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="escrow"
                                >
                                  Escrow
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="input_field">
                          <label
                            for="main_marks_and_distribution"
                            class="form-label"
                          >
                            Language Spoken:
                          </label>
                          <div className="row">
                            <div className="col-sm-4">
                              <div class="form-check">
                                <input
                                  class="form-check-input language_check"
                                  type="checkbox"
                                  value="english"
                                  id="english"
                                  onChange={handleLanguagesSpoken}
                                  checked={languagesSpoken?.includes("english")}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="english"
                                >
                                  English
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input language_check"
                                  type="checkbox"
                                  value="japanese"
                                  id="japanese"
                                  onChange={handleLanguagesSpoken}
                                  checked={languagesSpoken?.includes("japanese")}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="japanese"
                                >
                                  Japanese
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input language_check"
                                  type="checkbox"
                                  value="arabic"
                                  id="arabic"
                                  onChange={handleLanguagesSpoken}
                                  checked={languagesSpoken?.includes("arabic")}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="arabic"
                                >
                                  Arabic
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input language_check"
                                  type="checkbox"
                                  value="korean"
                                  id="korean"
                                  onChange={handleLanguagesSpoken}
                                  checked={languagesSpoken?.includes("korean")}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="korean"
                                >
                                  Korean
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-check">
                                <input
                                  class="form-check-input language_check"
                                  type="checkbox"
                                  value="chinese"
                                  id="chinese"
                                  onChange={handleLanguagesSpoken}
                                  checked={languagesSpoken?.includes("chinese")}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="chinese"
                                >
                                  Chinese
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input language_check"
                                  type="checkbox"
                                  value="portuguese"
                                  id="portuguese"
                                  onChange={handleLanguagesSpoken}
                                  checked={languagesSpoken?.includes(
                                    "portuguese"
                                  )}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="portuguese"
                                >
                                  Portuguese
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input language_check"
                                  type="checkbox"
                                  value="french"
                                  id="french"
                                  onChange={handleLanguagesSpoken}
                                  checked={languagesSpoken?.includes("french")}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="french"
                                >
                                  French
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input language_check"
                                  type="checkbox"
                                  value="hindi"
                                  id="hindi"
                                  onChange={handleLanguagesSpoken}
                                  checked={languagesSpoken?.includes("hindi")}
                                />
                                <label class="form-check-label m-0" for="hindi">
                                  Hindi
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div class="form-check">
                                <input
                                  class="form-check-input language_check"
                                  type="checkbox"
                                  value="spanish"
                                  id="spanish"
                                  onChange={handleLanguagesSpoken}
                                  checked={languagesSpoken?.includes("spanish")}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="spanish"
                                >
                                  Spanish
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input language_check"
                                  type="checkbox"
                                  value="german"
                                  id="german"
                                  onChange={handleLanguagesSpoken}
                                  checked={languagesSpoken?.includes("german")}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="german"
                                >
                                  German
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input language_check"
                                  type="checkbox"
                                  value="russian"
                                  id="russian"
                                  onChange={handleLanguagesSpoken}
                                  checked={languagesSpoken?.includes("russian")}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="russian"
                                >
                                  Russian
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input language_check"
                                  type="checkbox"
                                  value="italian"
                                  id="italian"
                                  onChange={handleLanguagesSpoken}
                                  checked={languagesSpoken?.includes("italian")}
                                />
                                <label
                                  class="form-check-label m-0"
                                  for="italian"
                                >
                                  Italian
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex bottom_bar py-3 justify-content-center align-center">
                          <p
                            className="back_button"
                            onClick={handleVisibFiveBack}
                          >
                            Back
                          </p>
                          <p
                            className="main_button"
                            style={{ margin: "0px 0px 0px 15px" }}
                            onClick={(e) => handleVisibSix(e)}
                          >
                            Next
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          showSeven ? "vendor_rStepSix vendor_step" : "hide_sec"
                        }
                      >
                        <div className="input_field">
                          <label for="company_logo" class="form-label">
                            Company Logo:
                          </label>
                          <input
                            type="file"
                            class="form-control"
                            id="company_logo"
                            ref={companylogoinputFileRef}
                            onChangeCapture={onLogoFileChangeCapture}
                          />
                          <div class="placeholder">
                            {/* <p>Maximum 2M support JPG and PNG format</p> */}
                          </div>
                          <div
                            className="Product-thumbnail"
                            onClick={companylogotriggerFile}
                          >
                            <img
                              src="../assets/img/default_image.png"
                              className="w-100"
                              ref={companylogoimgRef}
                            />
                          </div>
                        </div>
                        <div class="label-main mb-3">
                          <div class="label-two">
                            <h2>Detailed Company Introduction:</h2>
                          </div>
                          <textarea
                            cols="30"
                            rows="5"
                            required
                            value={companyIntro}
                            onChange={(e) => setCompanyIntro(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="input_field">
                          <label for="company_photo" class="form-label">
                            Company Photo:
                          </label>
                          <input
                            type="file"
                            class="form-control"
                            id="company_photo"
                            ref={companyphotoinputFileRef}
                            onChangeCapture={onPhotoFileChangeCapture}
                          />
                          <div class="placeholder">
                            {/* <p>Maximum 2M support JPG and PNG format</p> */}
                          </div>
                          <div
                            className="Product-thumbnail"
                            onClick={companyphototriggerFile}
                          >
                            <img
                              src="../assets/img/default_image.png"
                              className="w-100"
                              ref={companyphotoimgRef}
                            />
                          </div>
                        </div>
                        <div className="main_yes_no">
                          <div className="main_label_check">
                            <label>
                              Have you attended or planned to attend any trade
                              shows?:
                            </label>
                          </div>
                          <div className="main_label_checkinner">
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="yes"
                                checked={attendTradeShows === "yes"}
                                onChange={(e) => {
                                  {
                                    setShowContentNine(true);
                                  }
                                  {
                                    setAttendTradeShows(e.target.value);
                                  }
                                }}
                              />
                              Yes
                            </label>
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                value="no"
                                checked={attendTradeShows === "no"}
                                onChange={(e) => {
                                  {
                                    setShowContentNine(false);
                                  }
                                  {
                                    setAttendTradeShows(e.target.value);
                                  }
                                }}
                              />
                              No
                            </label>
                          </div>
                        </div>

                        {showContentNine && (
                          <div class="upload-main">
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="trade_showName" class="form-label">
                                  Trade Show Name:
                                </label>
                                <input
                                  type="name"
                                  class="form-control"
                                  id="trade_showName"
                                  value={tradeShowName}
                                  onChange={(e) =>
                                    setTradeShowName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="input_field">
                              <label for="Date Attended" class="form-label">
                                Date Attended:
                                <span className="astrick">*</span>
                              </label>
                              <div className="row">
                                <div className="col-sm-6">
                                  <select
                                    class="form-select"
                                    required
                                    aria-label="Default select example"
                                    value={dateAttendedYear}
                                    onChange={(e) =>
                                      setDateAttendedYear(e.target.value)
                                    }
                                  >
                                    <option value="">Select Year</option>
                                    {years.map((year) => (
                                      <option key={year} value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-sm-6">
                                  <select
                                    class="form-select"
                                    aria-label="Default select example"
                                    value={dateAttendedMonth}
                                    onChange={(e) =>
                                      setDateAttendedMonth(e.target.value)
                                    }
                                  >
                                    <option value="">Select Month</option>
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                  </select>
                                </div>
                              </div>
                              <div className="input_field">
                                <label for="host_country" class="form-label">
                                  Host Country/Region:
                                  <span className="astrick">*</span>
                                </label>
                                <CountryDropdown
                                  value={hostCountry}
                                  onChange={(val) => setHostCountry(val)}
                                  className="form-select"
                                />
                              </div>
                            </div>

                            <div class="label-main mb-3">
                              <div class="label-two">
                                <h2>Trade Show Introduction:</h2>
                              </div>
                              <textarea
                                cols="30"
                                rows="5"
                                value={tradeShowIntroduction}
                                onChange={(e) =>
                                  setTradeShowIntroduction(e.target.value)
                                }
                              ></textarea>
                            </div>
                            <div class="label-main mb-3">
                              <div className="input_field">
                                <label for="trade_showPhoto" class="form-label">
                                  Trade Show Photo:
                                </label>
                                <input
                                  type="file"
                                  class="form-control"
                                  id="trade_showPhoto"
                                  ref={tradeinputFileRef}
                                  onChangeCapture={onTradeFileChangeCapture}
                                />
                                <div class="placeholder">
                                  <p>Maximum 2M support JPG and PNG format</p>
                                </div>
                                <div
                                  className="Product-thumbnail"
                                  onClick={tradetriggerFile}
                                >
                                  <img
                                    src="../assets/img/default_image.png"
                                    className="w-100"
                                    ref={tradeimgRef}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="d-flex bottom_bar py-3 justify-content-center align-center">
                          <p
                            className="back_button"
                            onClick={handleVisibSixBack}
                          >
                            Back
                          </p>
                          <button
                            className="main_button"
                            type="submit"
                            style={{ margin: "0px 0px 0px 15px" }}
                            onClick={(e) => handleSubmitSeven(e)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>



                    </div>
                  </>
                </div>
              </form>
            </div>
            <Footer />
          </div>
        </section>
      </div>
    </>
  );
};

export default EditCompany;
