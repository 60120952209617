import React, { useEffect, useState, Fragment } from "react";
import Header from "../common/Header";
import Sidebar from "../common/sidebar";
import Footer from "../common/Footer";
import { useParams } from "react-router-dom";
import DataService from "../services/data.service";
import HelperService from "../services/helper.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import "../CustomCss/OrderProcess.css";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";

const OrderDetails = () => {
  let initialRows = 10;
  const DEFAULT_DATE = null;
  const MIN_TIME = "10:00";
  const MAX_TIME = "16:00";
  const params = useParams();
  const [data, setData] = useState([]);
  const [noOfDays, setNoOfDays] = useState("");
  const [fromTimes, setFromTimes] = useState(Array(initialRows).fill(MIN_TIME));
  const [toTimes, setToTimes] = useState(Array(initialRows).fill(MAX_TIME));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Order Details";
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);
    await DataService.getOrderDetail(params.id)
      .then((data) => {
        setData(data?.data?.data);
        initialRows = data?.data?.data?.OrderItems?.length - 1;
        setLoading(false);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const [selectedDates, setSelectedDates] = useState(
    Array(initialRows).fill(DEFAULT_DATE)
  );
  const handleDateChange = (newValue, index) => {
    const updatedDates = [...selectedDates];
    updatedDates[index] = newValue;
    setSelectedDates(updatedDates);
  };
  const shouldDisableDate = (date) => {
    const day = date.day();
    return day === 0 || day === 6 || date.isBefore(dayjs(), "day");
  };

  const validateTime = (from, to) => {
    if (from < MIN_TIME) {
      return "From Time cannot be earlier than 10:00 AM.";
    }
    if (to > MAX_TIME) {
      return "To Time cannot be later than 4:00 PM.";
    }
    if (from >= to) {
      return "From Time must be earlier than To Time.";
    }
    return "";
  };

  const handleFromTimeChange = (e, index) => {
    const newFromTime = e.target.value;
    const updatedFromTimes = [...fromTimes];
    if (newFromTime < MIN_TIME || newFromTime > MAX_TIME) {
      updatedFromTimes[index] = MIN_TIME;
    } else {
      updatedFromTimes[index] = newFromTime;
    }
    setFromTimes(updatedFromTimes);
    const errorMessage = validateTime(updatedFromTimes[index], toTimes[index]);
    if (errorMessage) {
      toast.error(errorMessage);
    }
  };

  const handleToTimeChange = (e, index) => {
    const newToTime = e.target.value;
    const updatedToTimes = [...toTimes];
    if (newToTime > MAX_TIME || newToTime < MIN_TIME) {
      updatedToTimes[index] = MAX_TIME;
    } else {
      updatedToTimes[index] = newToTime;
    }
    setToTimes(updatedToTimes);
    const errorMessage = validateTime(fromTimes[index], updatedToTimes[index]);
    if (errorMessage) {
      toast.error(errorMessage);
    }
  };
  const handleSubmit = (index, oId, oItemId) => {
    if (selectedDates[index]) {
      setBtnLoading(true);
      const date = new Date(selectedDates[index]);
      const formattedDate = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(
        2,
        "0"
      )}T00:00:00.000Z`;
      const data = {
        pickup_date: formattedDate,
        from_time: fromTimes[index],
        to_time: toTimes[index],
        order_id: oId,
        order_item_id: oItemId,
      };
      DataService.addSchedule(data).then(
        () => {
          toast.success("Pickup Scheduled for this product.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          getData();
          setBtnLoading(false);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.msg) ||
            error.message ||
            error.toString();
          setBtnLoading(false);
          toast.error(resMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );
    } else {
      toast.error("Date is Required.");
    }
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const createShipment = (id) => {
    setBtnLoading(true);
    const data = {
      order_id: id,
    };
    DataService.createNewShipment(data).then(
      () => {
        toast.success("Shipment Created Successfully!!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        getData();
        setBtnLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();
        setBtnLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  const handleDispatchCancel = (id) => {
    if (id) {
      setBtnLoading(true);
      const data = {
        dispatchConfirmationNumber: id,
      };
      DataService.cancelDispatch(data).then(
        () => {
          toast.success("Scheduled Pickup Cancelled for this product.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          getData();
          setBtnLoading(false);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.msg) ||
            error.message ||
            error.toString();
          setBtnLoading(false);
          toast.error(resMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );
    } else {
      toast.error("Got Some Error. Please Try Again!!");
    }
  };
  const [trackingData, setTrackingData] = useState([]);
  const [trackPopup, setTrackPopup] = useState(false);
  const [trackEvents, setTrackEvents] = useState([])
  const trackPackage = (id) => {
    if (id) {
      setBtnLoading(true);
      const data = {
        trackingNumber: id,
      };
      DataService.trackPack(data).then(
        (res) => {
          setTrackingData(res?.data?.shipments?.[0]);
          setTrackEvents(res?.data?.shipments?.[0]?.events?.reverse())
          setTrackPopup(true);
          toast.success("Product Tracked", {
            position: toast.POSITION.TOP_RIGHT,
          });
          // getData();
          setBtnLoading(false);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.msg) ||
            error.message ||
            error.toString();
          setBtnLoading(false);
          toast.error(resMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );
    } else {
      toast.error("Got Some Error. Please Try Again!!");
    }
  };
  return (
    <div className="bg-grey h-100">
      <Header />
      <section className="content-area mt-4">
        <Sidebar />
        <div className="Right-content">
          <div className="main-content">
            <div className="container p-4 mb-5">
              <ToastContainer></ToastContainer>
              {/* <div className="Order_process">
                <div className="container">
                  <div className="Order_process_inner">
                    <h1>Order Process</h1>
                    <ol className="steps">
                      <li className="step is-complete" data-step="1">
                        Payment has been processed
                      </li>
                      <li className="step is-complete" data-step="2">
                        Preparing the good to be shipped
                      </li>
                      <li className="step is-active" data-step="3">
                        Shipping completed
                      </li>
                      <li className="step" data-step="4">
                        Full transactions done ✔️
                      </li>
                    </ol>
                  </div>
                </div>
              </div> */}

              <div className="row">
                {!loading ? (
                  <>
                    <div
                      id="dnld"
                      className="row py-4"
                      style={{ borderBottom: "1px solid #000000" }}
                    >
                      <div className="col-xl-4 col-lg-4 col-md-6 mb-md-3">
                        <div
                          className="bg-grey align-items-center justify-content-between d-flex p-4 py-5"
                          style={{ borderRadius: "5px" }}
                        >
                          <div className="pe-2">
                            <h4>Billing Address</h4>
                            <h6>
                              {data?.BillingAddress?.first_name}{" "}
                              {data?.BillingAddress?.last_name},<br></br>
                              {data?.BillingAddress?.address},<br></br>
                              {data?.BillingAddress?.city},{" "}
                              {data?.BillingAddress?.region}{" "}
                              {data?.BillingAddress?.landmark
                                ? "(" + data?.BillingAddress?.landmark + ")"
                                : ""}
                              <br></br>
                              {data?.BillingAddress?.phone}
                              <br></br>
                            </h6>
                          </div>
                          <i>
                            <svg
                              width="136"
                              height="136"
                              viewBox="0 0 136 136"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="order-detail-svg"
                            >
                              <path
                                d="M113.335 45.3333H90.668C87.268 45.3333 85.0013 47.5999 85.0013 50.9999V90.6666H56.668V96.3333C56.668 99.7333 58.9346 102 62.3346 102H90.668C90.668 95.7666 95.768 90.6666 102.001 90.6666C108.235 90.6666 113.335 95.7666 113.335 102H119.001C122.401 102 124.668 99.7333 124.668 96.3333V73.6666L113.335 45.3333Z"
                                fill="black"
                              />
                              <path
                                opacity="0.3"
                                d="M113.332 102C113.332 108.233 108.232 113.333 101.999 113.333C95.7654 113.333 90.6654 108.233 90.6654 102C90.6654 95.7667 95.7654 90.6667 101.999 90.6667C108.232 90.6667 113.332 95.7667 113.332 102ZM84.9987 22.6667C84.9987 19.2667 82.732 17 79.332 17H16.9987C13.5987 17 11.332 19.2667 11.332 22.6667V73.6667C11.332 77.0667 13.5987 79.3333 16.9987 79.3333H84.9987V22.6667ZM33.9987 90.6667C27.7654 90.6667 22.6654 95.7667 22.6654 102C22.6654 108.233 27.7654 113.333 33.9987 113.333C40.232 113.333 45.332 108.233 45.332 102C45.332 95.7667 40.232 90.6667 33.9987 90.6667Z"
                                fill="#F4AC3D"
                              />
                            </svg>
                          </i>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6">
                        <div
                          className="bg-grey align-items-center justify-content-between d-flex p-4 py-5"
                          style={{ borderRadius: "5px" }}
                        >
                          <div className="pe-2">
                            <h4>Shipping Address</h4>
                            <h6>
                              {data?.Address?.first_name}{" "}
                              {data?.Address?.last_name},<br></br>
                              {data?.Address?.address},<br></br>
                              {data?.Address?.city}, {data?.Address?.region}{" "}
                              {data?.Address?.landmark
                                ? "(" + data?.Address?.landmark + ")"
                                : ""}
                              <br></br>
                              {data?.Address?.phone}
                              <br></br>
                            </h6>
                          </div>
                          <i>
                            <svg
                              width="136"
                              height="136"
                              viewBox="0 0 136 136"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="order-detail-svg"
                            >
                              <path
                                d="M113.335 45.3333H90.668C87.268 45.3333 85.0013 47.5999 85.0013 50.9999V90.6666H56.668V96.3333C56.668 99.7333 58.9346 102 62.3346 102H90.668C90.668 95.7666 95.768 90.6666 102.001 90.6666C108.235 90.6666 113.335 95.7666 113.335 102H119.001C122.401 102 124.668 99.7333 124.668 96.3333V73.6666L113.335 45.3333Z"
                                fill="black"
                              />
                              <path
                                opacity="0.3"
                                d="M113.332 102C113.332 108.233 108.232 113.333 101.999 113.333C95.7654 113.333 90.6654 108.233 90.6654 102C90.6654 95.7667 95.7654 90.6667 101.999 90.6667C108.232 90.6667 113.332 95.7667 113.332 102ZM84.9987 22.6667C84.9987 19.2667 82.732 17 79.332 17H16.9987C13.5987 17 11.332 19.2667 11.332 22.6667V73.6667C11.332 77.0667 13.5987 79.3333 16.9987 79.3333H84.9987V22.6667ZM33.9987 90.6667C27.7654 90.6667 22.6654 95.7667 22.6654 102C22.6654 108.233 27.7654 113.333 33.9987 113.333C40.232 113.333 45.332 108.233 45.332 102C45.332 95.7667 40.232 90.6667 33.9987 90.6667Z"
                                fill="#F4AC3D"
                              />
                            </svg>
                          </i>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6">
                        <div className="bg-grey p-4 rounded-2">
                          <h4 className="f-700">
                            Order Details (#A5{data.id})
                          </h4>
                          <div className="w-100 d-flex justify-content-between align-items-center border-bottom py-2">
                            <span>
                              <i className="me-2">
                                <img src="../assets/img/file-earmark-text.png" />
                              </i>{" "}
                              Invoice Id
                            </span>
                            <span>
                              <h6>#A5{data.id}</h6>
                            </span>
                          </div>
                          <div className="w-100 d-flex justify-content-between align-items-center border-bottom py-2">
                            <span>
                              <i className="me-2">
                                {" "}
                                <img src="../assets/img/truck.png" />
                              </i>{" "}
                              Payment Method
                            </span>
                            <span>
                              <h6>{data?.payment_method}</h6>
                            </span>
                          </div>

                          <div className="w-100 d-flex justify-content-between align-items-center py-2">
                            <span>
                              <i className="me-2">
                                <img src="../assets/img/order_bag.png" />
                              </i>{" "}
                              Order Id
                            </span>
                            <span>
                              <h6>#{data?.id}</h6>
                            </span>
                          </div>
                          <div className="w-100 d-flex justify-content-between align-items-center py-2">
                            <span>
                              <i className="me-2">
                                <img src="../assets/img/file-earmark-text.png" />
                              </i>{" "}
                              Status
                            </span>
                            <span>
                              <h6>
                                {HelperService.orderStatus()[data.status]}
                              </h6>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 text-end mt-4">
                        <Link to={"/invoice-details/" + data.id}>
                          <button className="btn btn-primary">
                            View Invoice
                          </button>
                        </Link>
                        <button
                          className="btn btn-danger ms-3"
                          style={{ background: "#cd4d36" }}
                          onClick={() => createShipment(data?.id)}
                          disabled={
                            data?.OrderItems?.[0]?.shipment_label || btnLoading
                          }
                        >
                          {data?.OrderItems?.[0]?.shipment_label
                            ? "Label Created"
                            : "Create Label"}
                        </button>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="pb-4">
                        {data?.OrderItems && data?.OrderItems.length > 0
                          ? data?.OrderItems?.map((item, i) => (
                              <div
                                className="row order-details-sec py-lg-5 py-md-3 py-sm-2"
                                style={{ borderBottom: "1px solid #000000" }}
                              >
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                  <div className="order-detail-img text-center">
                                    <img
                                      src={
                                        item.Product.file_path
                                          ? "https://api.dealitt.com/" +
                                            item.Product.file_path
                                          : ""
                                      }
                                      alt="product"
                                      srcset=""
                                      width="200"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                  <div className="order-detail-title">
                                    {item.status == "inactive" && (
                                      <div className="address-tag bg-danger">
                                        Cancelled
                                      </div>
                                    )}

                                    {item?.status == "inactive" ? (
                                      <div className="cancel_reason">
                                        <b>Reason : </b>
                                        {item?.cancel_reason}{" "}
                                        {item.cancel_by == 1
                                          ? "(Admin)"
                                          : "(User)"}
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    <h3>
                                      {item?.Product?.name}
                                      {item?.variant
                                        ? " - " + item?.variant
                                        : ""}{" "}
                                      x ({item?.quantity})
                                    </h3>
                                    <h4>£ {item?.price}</h4>
                                    {/* <div className="address-tag">
                                      4.6 <i class="fas fa-star"></i>
                                    </div> */}
                                  </div>
                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-12">
                                  <p className="mb-2">
                                    <strong>
                                      Please Select Date & Time to reschedule:
                                    </strong>
                                  </p>
                                  <div class="row price-row">
                                    <div className="col-md-12 mb-3 dater">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DatePicker
                                          label="Select Date"
                                          value={selectedDates[i] || null}
                                          onChange={(newValue) =>
                                            handleDateChange(newValue, i)
                                          }
                                          shouldDisableDate={shouldDisableDate}
                                          disablePast
                                          slotProps={{
                                            textField: {
                                              inputProps: { readOnly: true },
                                              fullWidth: true,
                                              variant: "outlined",
                                            },
                                          }}
                                          sx={{ width: "100%" }}
                                        />
                                      </LocalizationProvider>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label className="form-label">
                                        From Time ?
                                      </label>
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={fromTimes[i] || MIN_TIME}
                                        min={MIN_TIME}
                                        max={MAX_TIME}
                                        onChange={(e) =>
                                          handleFromTimeChange(e, i)
                                        }
                                        required
                                      />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label className="form-label">
                                        To Time ?
                                      </label>
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={toTimes[i] || MAX_TIME}
                                        min={MIN_TIME}
                                        max={MAX_TIME}
                                        onChange={(e) =>
                                          handleToTimeChange(e, i)
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="row price-row">
                                    <div className="col-sm-12 d-flex justify-content-end">
                                        <button
                                          className="btn btn-primary"
                                          style={{
                                            background: "rgb(20, 108, 48)",
                                          }}
                                          disabled={btnLoading}
                                          onClick={(e) =>
                                            trackPackage("2449648740")
                                          }
                                        >
                                          Track Product
                                        </button>
                                      {item?.dispatchConfirmationNumber && (
                                        <button
                                          className="btn btn-primary ms-3"
                                          style={{
                                            background: "rgb(205, 77, 54)",
                                          }}
                                          disabled={btnLoading}
                                          onClick={(e) =>
                                            handleDispatchCancel(
                                              item?.dispatchConfirmationNumber
                                            )
                                          }
                                        >
                                          Cancel Pickup
                                        </button>
                                      )}
                                      {!item?.dispatchConfirmationNumber && (
                                        <button
                                          className="btn btn-primary ms-3"
                                          disabled={btnLoading}
                                          onClick={(e) =>
                                            handleSubmit(
                                              i,
                                              item?.order_id,
                                              item?.id
                                            )
                                          }
                                        >
                                          Schedule Pickup
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          : ""}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-lg-6 m-auto">
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </section>
      {trackPopup && (
        <div className="trackOuter">
          <div className="trackInner">
            <i
              className="fas fa-times"
              onClick={() => {
                setTrackPopup(false);
                setTrackingData([]);
              }}
            />
            <div className="tracking-container">
              <h2 className="tracking-title">Package Tracking</h2>
              <p className="mb-4 text-center">#{trackingData?.shipmentTrackingNumber}</p>
              <div className="timeline">
                {trackEvents?.map((event, index) => (
                  <div
                    className={`timeline-item ${
                      event?.typeCode === "OK" ? "delivered" : ""
                    }`}
                    key={index}
                  >
                    <div className="timeline-dot"></div>
                    <div className="timeline-date">
                      <p>{moment(event?.date).format("ll")}</p>
                      <p>{event?.time}</p>
                    </div>
                    <div className="timeline-content">
                      <h3 className="event-description">
                        {event?.description}
                      </h3>
                      <p className="service-area">
                        Location: {event?.serviceArea[0].description}
                      </p>
                      {event?.signedBy && <p>Signed by: {event?.signedBy}</p>}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
