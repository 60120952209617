import axios from "axios";
import AuthService from "../services/auth.service";

const API_URL =
  process.env.NODE_ENV != "production"
    ? "https://api.dealitt.com/"
    : "https://api.dealitt.com/";

axios.interceptors.request.use(function (config) {
  const token = AuthService.getCurrentUserTokken();
  config.headers.Authorization = "Bearer " + token;

  return config;
});
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("user");
      window.location.href = "/login";
      // Hace la solicitud de refresco de tokens
    }
    return Promise.reject(error);
  }
);
const addCategory = (data) => {
  return axios.post(API_URL + "api/categories", data);
};

const getCountries = () => {
  return axios.get(API_URL + "api/front/countries");
};
const getStates = (country_id) => {
  return axios.get(API_URL + "api/front/states/" + country_id);
};
const getCity = (state_id) => {
  return axios.get(API_URL + "api/front/cities/" + state_id);
};

const getCategory = (type) => {
  return axios.get(API_URL + "api/categories?type=" + type);
};

const getAllCategory = (type) => {
  return axios.get(API_URL + "api/categories");
};
const addProduct = (data) => {
  return axios.post(API_URL + "api/products", data);
};
const getProduct = (page = 1, limit = 15, search = "", status = "") => {
  return axios.get(
    API_URL +
      "api/vendor/products?page=" +
      page +
      "&limit=" +
      limit +
      "&search=" +
      search +
      "&status=" +
      status
  );
};
const updateProduct = (data, id) => {
  return axios.put(API_URL + "api/products/" + id, data);
};
const deleteProductImage = (id, key) => {
  return axios.delete(API_URL + "api/products/" + id + "/image/" + key);
};
const deleteProductDocument = (id, key) => {
  return axios.delete(API_URL + "api/products/" + id + "/document/" + key);
};
const getProductDetail = (id) => {
  return axios.get(API_URL + "api/products/" + id);
};
const addBrand = (data) => {
  return axios.post(API_URL + "api/brands", data);
};
const getAllBrand = (type) => {
  return axios.get(API_URL + "api/vendor/brands");
};
const getBrandDetail = (id) => {
  return axios.get(API_URL + "api/brands/" + id);
};
const updateBrand = (data, id) => {
  return axios.put(API_URL + "api/brands/" + id, data);
};
const deleteBrand = (id) => {
  return axios.delete(API_URL + "api/brands/" + id);
};
const getOrder = () => {
  return axios.get(API_URL + "api/order/vendor");
};
const getOrderDetail = (id) => {
  return axios.get(API_URL + "api/order/" + id);
};
const changePassword = (data) => {
  return axios.post(API_URL + "api/users/changepassword", data);
};
const getUserDetail = () => {
  return axios.get(API_URL + "api/users/detail");
};
const updateUser = (data, id) => {
  return axios.put(API_URL + "api/users/" + id, data);
};
const getDashboard = () => {
  return axios.get(API_URL + "api/dashboard");
};
const forgotPassword = (data) => {
  return axios.post(API_URL + "api/users/forgotpassword", data);
};
const verifyUserOtp = (data) => {
  return axios.post(API_URL + "api/users/verifyotp", data);
};
const resetPassword = (data) => {
  return axios.post(API_URL + "api/users/resetpassword", data);
};
const getAllReviews = () => {
  return axios.get(API_URL + "api/review");
};
const getProductReview = (id) => {
  return axios.get(API_URL + "api/products/" + id + "/reviews");
};
const getNotifications = (read = false) => {
  return axios.get(
    API_URL + "api/users/notifications" + (read == true ? "?read=yes" : "")
  );
};
const addVendor = (data) => {
  return axios.post(API_URL + "api/vendor/store", data);
};
const updateVendorStore = (data) => {
  return axios.put(API_URL + "api/vendor/store", data);
};
const getVendor = (data) => {
  return axios.get(API_URL + "api/vendor/store", data);
};
const getChatWithVendor = (id) => {
  return axios.get(API_URL + "api/chats/vendor/" + id);
};
const addChatWithVendor = (data) => {
  return axios.post(API_URL + "api/chats", data);
};
const getAllChats = (data) => {
  return axios.get(API_URL + "api/chats", data);
};
const getSingleChat = (id) => {
  return axios.get(API_URL + "api/chats/" + id);
};
const getContinents = () => {
  return axios.get(API_URL + "api/front/continents");
};
const getUser = (type) => {
  return axios.get(API_URL + "api/users?role=" + type);
};
const createAccount = (data) => {
  return axios.post(API_URL + "api/stripe/createAccount", data);
};
const addBankAccount = (data) => {
  return axios.post(API_URL + "api/stripe/addBankAccount", data);
};
const addProfile = (data) => {
  return axios.post(API_URL + "api/stripe/addProfile", data);
};
const updateAccount = (data) => {
  return axios.post(API_URL + "api/stripe/updateAccount", data);
};
const confirmStripe = () => {
  return axios.post(API_URL + "api/users/confirmStripe");
};
const getStripeData = (data) => {
  return axios.post(API_URL + "api/stripe/accountDetails", data);
};
const addSchedule = (data) => {
  return axios.post(API_URL + "api/shipping/requestPickup", data);
};
const createNewShipment = (data) => {
  return axios.post(API_URL + "api/shipping/createShipment", data);
};
const cancelDispatch = (data) => {
  return axios.post(API_URL + "api/shipping/cancelPickup", data);
};
const trackPack = (data) => {
  return axios.post(API_URL + "api/shipping/track", data);
};

const DataService = {
  trackPack,
  cancelDispatch,
  createNewShipment,
  addSchedule,
  confirmStripe,
  getUser,
  getContinents,
  addCategory,
  getCategory,
  getAllCategory,
  addProduct,
  getProduct,
  addBrand,
  getAllBrand,
  updateProduct,
  getProductDetail,
  getBrandDetail,
  updateBrand,
  getOrder,
  getOrderDetail,
  deleteBrand,
  deleteProductImage,
  deleteProductDocument,
  changePassword,
  getUserDetail,
  getDashboard,
  forgotPassword,
  verifyUserOtp,
  resetPassword,
  getAllReviews,
  getProductReview,
  updateUser,
  getNotifications,
  addVendor,
  getVendor,
  getChatWithVendor,
  getAllChats,
  getSingleChat,
  addChatWithVendor,
  updateVendorStore,
  getCountries,
  getStates,
  getCity,
  createAccount,
  addBankAccount,
  addProfile,
  updateAccount,
  getStripeData,
};
export default DataService;
